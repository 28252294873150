import axios from 'axios';

const base = `https://helwa.io:5000/api/`;
const base1 = `https://helwa.io:5000/`;
//const base = `http://localhost/pte_pro/public`;
const axiosInstance = axios.create({
    baseURL: base

});

const axiosInstance1 = axios.create({
    baseURL: base1
});

axiosInstance1.defaults.headers.common['Authorization'] = localStorage.getItem('token');
axiosInstance1.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axiosInstance1.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
)

axiosInstance.defaults.headers.common['Authorization'] = localStorage.getItem('token');
axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
)



const errorHandler = (error) => {

    return error;
}


const requestHandler = (request) => {

    return request
}

axiosInstance.interceptors.request.use(
    request => requestHandler(request)
)

axiosInstance1.interceptors.request.use(
    request => requestHandler(request)
)

const successHandler = (response) => {
    return response
}

export default axiosInstance;
export { base, axiosInstance1 };