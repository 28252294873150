import React from 'react';
import { Link } from 'react-router-dom';
import strings from '../lang/index';
class Faq extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        
    }
 render(){  
	return (
		<section class="faqsection" id="faq">
        <div class="container">
            <div class="row">
                <div class="col-lg-12"><h3>{strings.faq_title}</h3></div>
            </div>
            
            <div class="faqdesktop">
                <div class="row">
                <div class="col-md-12 p-0 mt-4">
                <div id="accordion" class="accordion faqaccordion">
                     <div class="card mb-0">
                        <div class="card-header collapsed" data-toggle="collapse" href="#collapseOne">
                            <a class="card-title">{strings.faq1_title}</a>
                        </div>
                        <div id="collapseOne" class="card-body collapse" data-parent="#accordion" >
                            <p>{strings.faq1_body}</p>
                        </div>
                        
                        <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                            <a class="card-title">{strings.faq2_title}</a>
                        </div>
                        <div id="collapseTwo" class="card-body collapse" data-parent="#accordion" >
                            <p>{strings.faq2_body}</p>
                        </div>
                        
                        <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                            <a class="card-title">{strings.faq3_title}</a>
                        </div>
                        <div id="collapseThree" class="collapse" data-parent="#accordion" >
                            <div class="card-body"><p>{strings.faq3_body}</p></div>
                        </div>
                        
                        <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour">
                            <a class="card-title">{strings.faq4_title}</a>
                        </div>
                        <div id="collapseFour" class="collapse" data-parent="#accordion" >
                            <div class="card-body"><p>{strings.faq4_body}</p></div>
                        </div>
                     </div>
                </div>
            </div>
            </div>
            </div>
            
            <div class="faqmob">
                <div class="row">
                <div class="col-md-12 p-0 mt-4">
                <div id="accordion" class="accordion faqaccordion">
                     <div class="card mb-0">
                        <div class="card-header collapsed" data-toggle="collapse" href="#collapseOne">
                            <a class="card-title">{strings.faq1_title}</a>
                        </div>
                        <div id="collapseOne" class="card-body collapse" data-parent="#accordion" >
                            <p>{strings.faq1_body}</p>
                        </div>
                        
                        
                        <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                            <a class="card-title">{strings.faq5_title}</a>
                        </div>
                        <div id="collapseThree" class="collapse" data-parent="#accordion" >
                            <div class="card-body"><p>{strings.faq5_body}</p></div>
                        </div>
                        
                        <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour">
                            <a class="card-title">{strings.faq6_title}</a>
                        </div>
                        <div id="collapseFour" class="collapse" data-parent="#accordion" >
                            <div class="card-body"><p>{strings.faq6_body1} <a href="mailto:admin@helwa.io">admin@helwa.io </a> {strings.faq6_body2}</p></div>
                        </div>
                     </div>
                </div>
            </div>
            </div>
            </div>
        </div>
        </section>
	);
}
}

export default Faq;