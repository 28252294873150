import React from 'react';
import strings from '../lang/index';
class HowWorks extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        
    }
 render(){  
	return (
        <React.Fragment>

		<section class="how-it-works" id="howwork">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h1>{strings.how_works_text1}</h1>
                </div>
            </div>
        
        <div class="how-desktop" 
        align="center">
        <br /><br />

        <iframe style={{width:'100%'}} height="600" src="https://www.youtube.com/embed/NgS6PfJwQ20" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <br /><br />
        <div class="row mt-4">
            <div class="col-lg-6 col-md-6"><img src="/images/step1.jpg" alt="" /></div>
            <div class="col-lg-6 col-md-6 my-auto textsection">
                <div class="numbertxt">01</div>
                <h3>{strings.install_for_free}</h3>
                <p>{strings.helwa_free_clicks}</p>
            </div>
        </div>
        
        <div class="row mt-4">
            <div class="col-lg-6 col-md-6 order-lg-2 order-md-2"><img src="/images/step2.jpg" alt="" /></div>
            <div class="col-lg-6 col-md-6 order-lg-1 order-md-1 my-auto textsection">
                <div class="numbertxt blue">02</div>
                <h3>{strings.shop_on_fav_site}</h3>
                <p>{strings.how_works_text2}</p>
            </div>
        </div>
        
        <div class="row mt-4">
            <div class="col-lg-6 col-md-6"><img src="/images/screen3.jpg" alt="" /></div>
            <div class="col-lg-6 col-md-6 my-auto textsection">
                <div class="numbertxt green">03</div>
                <h3>{strings.save_money}</h3>
                <p>{strings.how_works_text3}</p>
            </div>
        </div>
        </div>
        
        <div class="how-mobile">
        <div class="row mt-4">
            <div class="col-lg-6 col-md-6"><img src="/images/mob-screen1.jpg" alt="" /></div>
            <div class="col-lg-6 col-md-6 my-auto textsection">
                <div class="numbertxt">01</div>
                <h3>{strings.how_works_text4}</h3>
                <p>{strings.how_works_text5}</p>
            </div>
        </div>
        
        <div class="row mt-4">
            <div class="col-lg-6 col-md-6 order-lg-2 order-md-2"><img src="/images/mob-screen2.jpg" alt="" /></div>
            <div class="col-lg-6 col-md-6 order-lg-1 order-md-1 my-auto textsection">
                <div class="numbertxt blue">02</div>
                <h3>{strings.copy_code}</h3>
                <p>{strings.how_works_text6}</p>
            </div>
        </div>
        
        <div class="row mt-4">
            <div class="col-lg-6 col-md-6"><img src="/images/mob-screen3.jpg" alt="" /></div>
            <div class="col-lg-6 col-md-6 my-auto textsection">
                <div class="numbertxt green">03</div>
                <h3>{strings.how_works_text7}</h3>
                <p>{strings.how_works_text8} </p>
            </div>
        </div>
        </div>
    </div>
    </section>
    </React.Fragment>
	);
}
}

export default HowWorks;