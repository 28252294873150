import React from 'react';
import strings from '../lang/index';
import Slider from "react-slick";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      ><img src="/images/next-slick.png" /></div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        onClick={onClick}
      ><img src="/images/prev-slick.png" /></div>
    );
  }
class BannerGuest extends React.Component {
 constructor(props) {
        super(props);
        this.state = {
            isChrome:false,
            isMob:false,
            imgPath:["/images/bannerbg.png"],
            ImgObj:this.props.ImgObj
        }
    }
    componentDidMount() {
        let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        let isMob = (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i)  || navigator.userAgent.match(/iPad/i)  || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i))?true:false;
        let pageArr = Object.keys(this.state.ImgObj);
        let imgPath = (pageArr.includes(this.props.page))?this.state.ImgObj[this.props.page]:this.state.imgPath;
        
        this.setState({isChrome:isChrome,isMob:isMob,imgPath:imgPath});
    }
 render(){
     
    let settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed:500,
        dots:true,
        autoplay:true
    };
	return (
		<section className="banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 bannerleft my-auto">
                        <h1 className="mb-3">{strings.never_pay_full_price}</h1>
                        <p className="desktop">{strings.helwa_find_coupon_text}</p>
                        <p className="mobdesc">{strings.use_helwa_coupons_txt}</p>
                        
                        <br/>
                        {!this.state.isChrome && !this.state.isMob && <div className="alert alert-warning alert-dismissible fade show" role="alert">
                          <strong>{strings.no_google1}</strong> {strings.no_google2}<br /><br />
                          <div className="btnleft"><a href="https://www.google.com/chrome/">{strings.get_chrome}</a></div><br />
                          <div class="btnright ml-5"><a href="#howwork"><span class="playbutton">&nbsp;</span> watch video</a></div>
                        </div>}
                        
                        {this.state.isChrome && <div className="buttonsection mt-5 d-flex">
                            <div className="btnleft"><a href="https://chrome.google.com/webstore/detail/helwa-automatic-coupon-fi/fipaemkffbblojagehfkanmbhpbonppd?authuser=0&hl=en">{strings.get_helwa_free}</a></div>
                            <div class="btnright ml-5"><a href="#howwork"><span class="playbutton">&nbsp;</span> watch video</a></div>
                        </div>}
                        
                        <div className="buttonmob">
                            <a href="https://apps.apple.com/us/app/id1519569679"><img src="images/appstoreicon.jpg" alt="" /></a>
                            <a href="https://play.google.com/store/apps/details?id=com.app.helwa"><img src="images/googleplay.jpg" alt="" /></a>
                        </div>
                        
                    </div>
                    <div className="col-lg-6 col-md-6 banner-right">
                    <Slider classNameName="testiContent regular02 slider" {...settings}>
                    {this.state.imgPath.length > 0 && this.state.imgPath.map((k)=>{
                          return (
                              <img src={k} alt="" />
                              );
                        })}
                    </Slider>
                    </div>
            </div>
            </div>
        </section>
	);
}
}

export default BannerGuest;