import React from 'react';
import Header from '../component/header';
import Footer from '../component/footer';
import strings from '../lang';

class CashbackTerms extends React.Component {
    componentDidMount() {
        
    }
 render(){   
    return (
    <React.Fragment>
        <Header page="faq" />
        <section class="banner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
        <h1>{strings.helwa_cb_term_heading}</h1>
        <br/>
        <br/>
        {strings.cb_term_text1}<br/>
        {strings.cb_term_text2}<br/>
        {strings.cb_term_text3}<br/>
        {strings.cb_term_text4}<br/>
        {strings.cb_term_text5}<br/>
        {strings.cb_term_text6}<br/>
         </div>
            </div>
            </div>
        </section>
        <Footer />
    </React.Fragment>);
    }
}

export default CashbackTerms;