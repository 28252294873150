import React from 'react';
import Header from '../component/header';
import Footer from '../component/footer';
import strings from '../lang/index';
import API,{axiosInstance1 as API1} from "../api.js";
class Privacy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId:(localStorage.getItem('user_id'))?localStorage.getItem('user_id'):"1",
            store:this.props.match.params.name
        }
      
    }
    componentDidMount(){
      this.LoadUrl();
    }

    LoadUrl = () =>{
      API1({
        method: 'GET',
        url: "apply_coupon?user_id="+this.state.userId+"&shop="+this.state.store,
      }).then(data => {
        
        if ('url' in data.data) {
          let url = "";
          if (data.data.url) {
            url = data.data.url;
          }else{
            url = data.data.homepage_url;
          }
          
         window.location.href = url;
        }
        //this.setState({cat:'featured',storeLoader:false,sites:data.data.websites,total:data.data.total_websites,totalPages:data.data.total_pages});
      });  
    }
    render(){
    return (
        <React.Fragment>
       
        <section class="banner">
            <div class="bg-contact100">
            <div class="container-contact100">
                <div>
                <div class="cb-elipse"><p className="cb-elipse-text">Up to 8% cashback</p></div>
                <p className="activated-txt-cb">ACTIVATED</p>
                </div>

            </div>
            </div>
        </section>
        <Footer />
    </React.Fragment>);
}
}

export default Privacy;