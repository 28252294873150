import React from 'react';
import { Link,Redirect } from 'react-router-dom';
import SocialButton from '../component/SocialButton';
import { GoogleLogin } from 'react-google-login';
import Header from '../component/header';
import Footer from '../component/footer';
import API from "../api.js";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import strings from '../lang/index';
import 'react-notifications/lib/notifications.css';
import SimpleReactValidator from 'simple-react-validator';
class Login extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          status:this.props.match.params.status

      }
  }
  componentDidMount() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
  }

 


 render(){
  if (this.state.login) {
     return <Redirect to='/'/>;
  }
  return (
    <React.Fragment>
    <NotificationContainer/>
    <Header />
      <section className="banner">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12">

        {this.state.status === 'success' && <div className="text-center">
        
            <img width="35%" src="/images/email-verified.png" />
            <h3 style={{fontWeight:"600"}}><span style={{color:'#f7c600'}}>Congratulations,</span> <span style={{color:'black'}}>Your email has been verified successfully!</span></h3>
        </div>}

        {this.state.status === 'error' && <div className="text-center">
        
            <img width="35%" src="/images/link-expired.svg" />
            <h3 style={{fontWeight:"600"}}><span style={{color:'red'}}>Sorry, link has been expired!</span></h3>
        </div>}
        
        
        
      </div>
  </div>
  </div>
</section>
<Footer />
    </React.Fragment>);
}
}
export default Login;