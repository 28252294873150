import React from 'react';
import Header from '../component/header';
import { Link, Redirect } from 'react-router-dom';
import Footer from '../component/footer';
import strings from '../lang/index';
import Slider from "react-slick";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import API, { axiosInstance1 as API1 } from "../api.js";
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className + " nxt-cr"}
            onClick={onClick}
        ><img src="/images/next-slick.png" /></div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className + " prv-cr"}
            onClick={onClick}
        ><img src="/images/prev-slick.png" /></div>
    );
}

class Term extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardArr: [],
            transArr: [],
            refArr: [],
            error: "",
            success: "",
            userId: (localStorage.getItem('user_id')) ? localStorage.getItem('user_id') : false,
            coins: "N/A",
            tab: 1,
            tranPage: 0,
            tranLoadMore:false,
            cardPage:0,
            cardLoadMore:false,
            referPage:0,
            referLoadMore:false,
            purchaseProgress:false
        }
    }
    componentDidMount() {
        this.loadGiftCard();
        this.loadReferal();
        this.loadTransaction();
        this.loadCoin();
    }

    loadCoin = () =>{
        this.setState({coins:"Loading..."})
        API({
            method: 'GET',
            url: "user/coins?user_id=" + this.state.userId,
        }).then(data => {

            console.log(data)
            if (data.status === 200) {
                this.setState({ coins: data.data.total_coins });
            }
        });
    }
    loadTransaction = () =>{
        this.setState({tranLoadMore:false});
        API({
            method: 'GET',
            url: "user/get_transactions?user_id=" + this.state.userId+"&pagination=true&page="+(this.state.tranPage+1),
        }).then(data => {
            this.setState({ transArr: [...this.state.transArr,...data.data.transactions], tranPage: this.state.tranPage+1,tranLoadMore:(data.data.next_page_url === null)?false:true });

        });
    }
    loadReferal = () => {
        this.setState({referLoadMore:false});
        API({
            method: 'GET',
            url: "user/get_reffered?pagination=true&user_id=" + this.state.userId + "page="+this.state.referPage,
        }).then(data => {
            this.setState({ refArr: [...this.state.refArr,...data.data.referrals],referLoadMore:(data.data.next_page_url === null)?false:true,referPage:this.state.referPage+1 });

        });
    }

    loadGiftCard = ()=>{
        this.setState({cardLoadMore:false});
        API({
            method: 'GET',
            url: "giftcards?pagination=true&page"+(this.state.cardPage+1)
        }).then(data => {

            this.setState({ cardArr: [...this.state.cardArr,...data.data.giftcards],cardPage:this.state.cardPage+1,cardLoadMore:(data.data.next_page_url === null)?false:true });

        });
    }

    changeTab = (tab) => {
        if(tab===1){
            this.loadCoin();
        }
        this.setState({ tab: tab })
    }

    redemCode = async (gift) => {
        this.setState({purchaseProgress:true});
        try {
            let res = await API({
                method: 'GET',
                url: "redeem?user_id=" + this.state.userId + "&gift_id=" + gift.id,
            });
            this.setState({purchaseProgress:false});
            if (res.status === 200) {
                
                confirmAlert({
                    customUI: ({ onClose }) => {
                      return (
                        <div class="react-confirm-alert">
                                <div class="react-confirm-alert-body"><b>{strings.congratulation_redeemed_text}</b><br/>
                                {strings.congratulation_redeemed_text2}
                                <div class="react-confirm-alert-button-group">
                                <button onClick={onClose}>{strings.ok}</button>
                                </div>
                            </div>
                        </div>
                      );
                    }
                  });
            } else if (res.response.status === 403) {
                confirmAlert({
                    customUI: ({ onClose }) => {
                      return (
                        <div class="react-confirm-alert">
                                <div class="react-confirm-alert-body"><b className="text-danger">{strings.less_points_err1}
                                </b><br/>{strings.less_points_err2}
                                <div class="react-confirm-alert-button-group">
                                <button onClick={onClose}>{strings.ok}</button>
                                </div>
                            </div>
                        </div>
                      );
                    }
                  });
                  
            }

        } catch (err) {

        }
    }
    render() {
        if (!this.state.userId) {
            return <Redirect to='/' />;
        }

        let settings = {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            dots: true,
            error: "",
            autoplay: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />

        };
        return (
            <React.Fragment>
                <Header page="term" />

                <section class="offers" id="offers">
                    <Slider classNameName=" regular02 slider" {...settings}>
                        <div class="banner-0-2-1304 ">
                            <div class="mainContainer-0-2-1348">
                                <div class="infoContainer-0-2-1347">
                                    <div class="title-0-2-1350 h1">{strings.save_money_earn_free_gift_cards_repeat}</div>
                                    <div class="subtitle-0-2-1349 body4">{strings.helwa_points_is_our_rewards_program_where_you_can_earn_gift_cards}</div>

                                </div>
                                <picture>
                                    <img alt="Gift cards on wooden blocks surrounded by Helwa Points coins" class="loading load image" src="/images/offers/gift-cards-banner-1-us.png" title="" />
                                </picture>
                            </div>
                        </div>
                        <div class="banner-0-2-1304 secondary" >
                            <div class="mainContainer-0-2-1360">
                                <div class="title-0-2-1367 h1">{strings.how_helwa_points_works}</div>
                                <div class="stepsContainer-0-2-1362">
                                    <div class="step-0-2-1361">
                                        <span class="stepNumber-0-2-1365 price5">01.</span>
                                        <picture>
                                            <img alt="Mouse clicking button with text &quot;Join Honey&quot; on it" class="loading load stepImage" src="/images/offers/join.png" title="" />
                                        </picture>
                                        <div class="stepCopy-0-2-1363 body5">
                                            {strings.create_account_text}
                                     <div class="stepSubtitle-0-2-1366 body4">{strings.free_and_secure}</div>
                                        </div>
                                    </div>
                                    <div class="step-0-2-1361">
                                        <span class="stepNumber-0-2-1365 price5">02.</span>
                                        <picture>
                                            <img alt="Floating coins with text &quot;Gold Available: 100 to 500&quot; on it" class="loading load stepImage" src="/images/offers/activate.png" title="" />
                                        </picture>
                                        <div class="stepCopy-0-2-1363 body5">
                                            {strings.activate_helwa_points}
                                     <div class="stepSubtitle-0-2-1366 body4">{strings.earn_helwa_points_when_you_shop}</div>
                                        </div>
                                    </div>
                                    <div class="step-0-2-1361">
                                        <span class="stepNumber-0-2-1365 price5">03.</span>
                                        <picture>
                                            <img alt="Gift cards floating with coins around it" class="loading load stepImage" src="/images/offers/redeem-us.png" title="" />
                                        </picture>
                                        <div class="stepCopy-0-2-1363 body5">
                                            {strings.redeem_for_gift_cards}
                                     <div class="stepSubtitle-0-2-1366 body4">{strings.get_gift_cards_from_popular_stores}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="banner-0-2-1304 secondary">
                            <div class="mainContainer-0-2-1380">
                                <picture>
                                    <img alt="Many gift cards in a row" class="loading load image" src="/images/offers/us-gift-cards-banner-3.png" title="" />
                                </picture>
                                <div class="infoContainer-0-2-1378">
                                    <div class="title-0-2-1382 h1"><span>{strings.earn_helwa_points_and_get_a_gift_card}</span></div>
                                    <div class="subtitle-0-2-1381 body4">{strings.earn_helwa_crausal_desc}</div>
                                    <button aria-label="Browse My Offers" type="submit" class="btn-0-2-30 btn-185-0-2-1386 primary-0-2-32 primary-186-0-2-1387" tabindex="-1">
                                        <div class="btnContentContainer-0-2-29">
                                            <div class="btnCopy-0-2-37">{strings.browse_gift_vouchers}</div>
                                        </div>
                                    </button>

                                    <div class="learnMore-0-2-1379 body4"><span>{strings.have_more_questions}</span><a aria-label="Learn more" href="#" rel="noopener noreferrer" target="_blank">{strings.learn_more}</a></div>

                                </div>
                            </div>
                        </div>
                    </Slider>
                    {/**/}
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">

                                <ul class="nav nav-tabs" role="tablist">

                                    <li class="nav-item">
                                        <a class={(this.state.tab === 1) ? "nav-link active" : "nav-link"} onClick={() => { this.changeTab(1) }} >{strings.OVERVIEW}</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class={(this.state.tab === 2) ? "nav-link active" : "nav-link"} onClick={() => { this.changeTab(2) }} >{strings.REDEEM}</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class={(this.state.tab === 3) ? "nav-link active" : "nav-link"} onClick={() => { this.changeTab(3) }} >{strings.referral_bonus}</a>
                                    </li>
                                </ul>


                                <div class="tab-content">


                                    {this.state.tab === 1 && <div class="tab-pane active" id="overview" role="tabpanel">

                                        <div class="row">
                                            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">

                                                <div class="n-main">
                                                    <div class="n-titleBar">
                                                        <span><picture><img alt="transaction history" class="t-loading t-load n-trxIcon" title="" src="/images/transaction-history-symbol.svg" srcset="" /></picture>
                                                            <span class="n-title">{strings.transaction_history}</span>
                                                        </span>
                                                        <div class="n-titleBarRight">
                                                            <span className="fa fa-refresh" title="Refresh" onClick={(e)=>{this.loadTransaction()}}></span>
                                                        </div>
                                                    </div>
                                                    <div class="n-content">
                                                        <div class="n-tableHeader">
                                                            <div class="n-tableHeaderContent">
                                                                <div class="n-date">{strings.DATE}</div>
                                                                <div class="n-transactionType">{strings.TRANSACTION}</div>
                                                                <div class="n-gold">{strings.HELWA_POINTS}</div>
                                                            </div>
                                                        </div>
                                                        {this.state.transArr.length === 0 && <div class="n-tableBody"><div>
                                                            <div class="a-main">
                                                                <div class="a-content">
                                                                    {strings.no_data_found}
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        </div>}
                                                        {!this.state.transArr.length && <div class="n-tableBody"><div>
                                                            <div class="a-main">
                                                                <div class="a-content">
                                                                    {strings.loading}
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        </div>}
                                                        {this.state.transArr.length > 0 && this.state.transArr.map((v, k) => {
                                                            return (<div class="n-tableBody"><div>
                                                                <div class="a-main">
                                                                    <div class="a-content">
                                                                        <div class="a-date">
                                                                            <span class="a-dateText">{v.transaction_date.replace('GMT', '').trim()}</span>

                                                                        </div>
                                                                        <div class="a-type">
                                                                            <div class="a-stateContent">
                                                                                <span>{v.transaction_message}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="a-valueDefault">
                                                                            {v.type === 'giftcard_transaction' && v.transaction_status === "approved" && <span className="text-danger">-{v.transaction_coins}</span>}
                                                                            {v.type === 'giftcard_transaction' && v.transaction_status === "pending" && <span className="text-warning">-{v.transaction_coins} (pending)</span>}
                                                                            {v.type !== 'giftcard_transaction' && v.transaction_status === "approved" && <span>+{v.transaction_coins}</span>}
                                                                            {v.type !== 'giftcard_transaction' && v.transaction_status === "pending" && <span className="text-warning">+{v.transaction_coins} (pending)</span>}
                                                                            {v.transaction_status === "rejected" && <span className="text-danger">{v.transaction_coins} (rejected)</span>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            </div>);
                                                        })}

                                                        
                                                    </div>
                                                    
                                                </div>
                                                <div className="text-center">
                                                { this.state. tranLoadMore && <button onClick={(e)=>{this.loadTransaction();}} style={{margin:'20px',padding:'10px'}}>Load More</button>}
                                                </div>
                                            </div>

                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">

                                                <div class="a-balanceCardContainer">
                                                    <div class="a-mainright">
                                                        <div class="a-top">
                                                            <div class="a-titleContainer">
                                                                <span class="a-of-title-rt">{strings.HELWA_POINTS}</span>

                                                            </div>
                                                            <div class="a-availablePoints">{this.state.coins}</div>
                                                        </div>
                                                        <div class="a-bottom">
                                                            <button type="submit" class="btn btn-redeem flexible" style={{ backgroundColor: '#f6c70b' }}>
                                                                <div onClick={() => { this.changeTab(2) }} class="btn-redeemCopy" style={{ color: '#000' }}>{strings.redeem}</div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>}
                                    {this.state.tab === 3 && <div class="tab-pane active" id="overview" role="tabpanel">

                                        <div class="row">
                                            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12">

                                                <div class="n-main">
                                                    <div class="n-titleBar">
                                                        <span><picture><img alt="transaction history" class="t-loading t-load n-trxIcon" title="" src="/images/transaction-history-symbol.svg" srcset="" /></picture>
                                                            <span class="n-title">{strings.referral_history}</span>
                                                        </span>
                                                        <div class="n-titleBarRight">
                                                        <span className="fa fa-refresh" title="Refresh" onClick={(e)=>{this.loadReferal()}}></span>
                                                        </div>
                                                    </div>
                                                    <div class="n-content">
                                                        <div class="n-tableHeader">
                                                            <div class="n-tableHeaderContent">
                                                                <div class="n-date">{strings.email_text}</div>
                                                                <div class="n-transactionType">{strings.name_text}</div>
                                                                <div class="n-gold">{strings.HELWA_POINTS}</div>
                                                            </div>
                                                        </div>
                                                        {this.state.refArr.length === 0 && <div class="n-tableBody"><div>
                                                            <div class="a-main">
                                                                <div class="a-content">
                                                                    {strings.no_data_found}
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        </div>}
                                                        {!this.state.refArr && <div class="n-tableBody"><div>
                                                            <div class="a-main">
                                                                <div class="a-content">
                                                                    {strings.loading}
                                                                    </div>
                                                            </div>
                                                        </div>
                                                        </div>}
                                                        {this.state.refArr.length > 0 && this.state.refArr.map((v, k) => {
                                                            return (<div class="n-tableBody"><div>
                                                                <div class="a-main">
                                                                    <div class="a-content">
                                                                        <div class="a-date">
                                                                            <span class="a-dateText">{v.referral_email}</span>

                                                                        </div>
                                                                        <div class="a-type">
                                                                            <div class="a-stateContent">
                                                                                <span>{v.referral_name}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="a-valueDefault">
                                                                            <span>{(v.converted)?'+'+v.reward_coins:<span className="text-danger">{strings.pending_brac}</span>}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>);
                                                        })}


                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                { this.state.referLoadMore && <button onClick={(e)=>{this.loadReferal();}} style={{margin:'20px',padding:'10px'}}>Load More</button>}
                                                </div>
                                            </div>

                                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">

                                                <div class="a-balanceCardContainer">
                                                    <div class="a-mainright">
                                                        <div class="a-top">
                                                            <div class="a-titleContainer">
                                                                <span class="a-of-title-rt">{strings.HELWA_POINTS}</span>

                                                            </div>
                                                            <div class="a-availablePoints">{this.state.coins}</div>
                                                        </div>
                                                        <div class="a-bottom">
                                                            <button type="submit" class="btn btn-redeem flexible" style={{ backgroundColor: '#f6c70b' }}>
                                                                <div onClick={() => { this.changeTab(2) }} class="btn-redeemCopy" style={{ color: '#000' }}>{strings.redeem}</div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>}

                                    {this.state.tab === 2 && <div class="tab-pane active" id="redeem" role="tabpanel">

                                        <div class="row">
                                            <div className="text-center">
                                                {this.state.error && <h4 class="text-danger">{this.state.error}</h4>}
                                                {this.state.success && <h4 class="text-success">{this.state.success}</h4>}
                                            </div>

                                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                <div class="offersList">
                                                    {this.state.purchaseProgress && <div className="text-center">
                                                            <h2>Please Wait...</h2>
                                                        </div>}
                                                    {!this.state.purchaseProgress && <ul>
                                                        {this.state.cardArr.map((v, k) => {
                                                            return (<li>
                                                                <div class="cardContainer popularCard textLeft">
                                                                    <div class="cardOverlay">
                                                                        <button aria-label="Shop" type="submit" class="btn-shop btn-secondary">
                                                                            <div class="btnContentContainer">
                                                                                <div class="btnCopy" onClick={(e) => { this.redemCode(v) }}>{strings.redeem}</div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div class="logoContainer">
                                                                        <div class="logoimg">
                                                                            <picture><img alt="Store" class="t-loading t-load logoImage" src={"data:image/png;base64," + v.image} /></picture>
                                                                        </div>
                                                                    </div>
                                                                    <div class="name label3">{v.name}</div>
                                                                    <div class="offer title1">
                                                                        <span>{v.points_required} {strings.points}</span>
                                                                    </div>
                                                                </div>
                                                            </li>);

                                                        })}
                                                    </ul>}
                                                </div>
                                                <div className="text-center">
                                                    { this.state. cardLoadMore && <button onClick={(e)=>{this.loadGiftCard();}} style={{margin:'20px',padding:'10px'}}>Load More</button>}
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="a-main">
                                            <div class="a-content">
                                                <div class="a-cardsWrapper">
                                                    <div class="main">
                                                        <div class="titleContainer">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />

            </React.Fragment>);
    }
}

export default Term;