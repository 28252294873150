import React from 'react';
import Header from '../component/header';
import Footer from '../component/footer';
class Home extends React.Component {
    componentDidMount() {
        function getMobileOperatingSystem() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }

            if (/android/i.test(userAgent)) {
                return "Android";
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }

            return "unknown";
        }
        let device = getMobileOperatingSystem();

        if(device =="Android"){
            window.location.href="https://play.google.com/store/apps/details?id=com.app.helwa";
        }else if(device=="iOS"){
            window.location.href="https://apps.apple.com/us/app/id1519569679";
        }else{
            window.location.href="https://helwa.io";
        }
    }
    
 render(){   
    return (
    <React.Fragment>
        <Header page="faq" />
        
    </React.Fragment>);
    }
}

export default Home;