import React from 'react';
import Header from '../component/header';
import Footer from '../component/footer';
import Faq from '../component/Faq';
class Home extends React.Component {
    componentDidMount() {
        
    }
 render(){   
    return (
    <React.Fragment>
        <Header page="faq" />
        <Faq />
        <Footer />
    </React.Fragment>);
    }
}

export default Home;