import React from 'react';

import strings from '../lang/index';
class HowWorks extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        
    }
 render(){  
	return (
        <React.Fragment>

		<section class="how-it-works" id="howwork">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h1>{strings.how_works_text1}</h1>
                </div>
            </div>
        
        <br /><br />

        <iframe style={{width:'100%'}} height="600" src="https://www.youtube.com/embed/NgS6PfJwQ20" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <br /><br />
        
        
       
    </div>
    </section>
    </React.Fragment>
	);
}
}

export default HowWorks;