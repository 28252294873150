import React from 'react';
import Header from '../component/header';
import Footer from '../component/footer';
import ShopList from '../component/ShopList';
import HowWorks from '../component/HowWorksVideo';
import Faq from '../component/Faq';
import BannerGuest from '../component/BannerGuest';
import LazyLoad from 'react-lazyload';
import News from '../component/News';
import { Redirect } from 'react-router-dom';
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ImgObj:{
                'kids':['/images/landing_kids.jpg','/images/landing_kids2.jpg'],
                'mens-shoes':['/images/landing_mshoes.jpg'],
                'mens-clothes':['/images/landing_mclothes.jpg','/images/landing_mclothes2.jpg'],
                'women-dress':['/images/landing_wdress.jpg','/images/landing_wdress2.jpg'],
                'women-handbag':['/images/landing_whandbag.jpg'],
                'women-makeup':['/images/landing_makeup1.jpg','/images/landing_makeup2.jpg'],
                'women-shoes':['/images/landing_wshoes.jpg'],
                'grocery':['/images/landing_grocery.jpg'],
                'gadgets':['/images/landing_gadgets.jpg','/images/landing_gadgets2.jpg']
            },
            redirect:false
            
        }
    }
    componentDidMount() {
        
    }
 render(){
    
    return (
    <React.Fragment>
    <Header />
    <LazyLoad once={true} >
    <BannerGuest ImgObj={this.state.ImgObj} page={this.props.match.params.page} />
    </LazyLoad>
    <LazyLoad once={true} >
    <News />
    <HowWorks />
    </LazyLoad>
    <LazyLoad once={true} >
    <ShopList />
    </LazyLoad>
    <LazyLoad once={true} >
    <Faq />
    </LazyLoad>
    <Footer />
    </React.Fragment>);
    }
}

export default Home;