import React from 'react';
import Header from '../component/header';
import Footer from '../component/footer';
import strings from '../lang/index';
class Term extends React.Component {
  
  render(){
        return (
        <React.Fragment>
        <Header page="term" />
        <section class="banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12">
        <h1>{strings.terms_conditions}</h1>
        <br />
        <br />
        {strings.term_text1}<br /><br />

        {strings.term_text2}<br /><br />
        {strings.term_text3}<br /><br />
        <strong>{strings.term_text4}</strong><br /><br />
        {strings.term_text5}<br /><br />
        {strings.term_text6}<br /><br />
        {strings.term_text7}<br /><br />
        <strong>{strings.term_text8}</strong><br /><br />
        {strings.term_text9} <br /><br />
        {strings.term_text10} <br />
        {strings.term_text11}<br />
        {strings.term_text12} <br />
        {strings.term_text13} <br />
        {strings.term_text14}<br />
        {strings.term_text15}<br />
        {strings.term_text16} <br />
        {strings.term_text17}<br />
        {strings.term_text18}<br /><br />

        <strong>{strings.term_text19}</strong><br /><br />
        {strings.term_text20}<br /><br />
        <strong>{strings.term_text21}</strong><br /><br />
        {strings.term_text22} <br /><br />
        {strings.term_text23}<br /><br />
        {strings.term_text24}<br /><br />
        <strong>{strings.term_text25}</strong><br /><br />
        {strings.term_text26}<br /><br />
        {strings.term_text27}<br /><br />
        <strong>{strings.term_text28}</strong><br /><br />
        {strings.term_text29}<br /><br />
        <strong>{strings.term_text30}</strong><br /><br />
        {strings.term_text31}<br /><br />
        <strong>{strings.term_text32}</strong><br /><br />
        {strings.term_text33}<br /><br />
        <strong>{strings.term_text34}</strong><br /><br />
        {strings.term_text35}<br /><br />
        <strong>{strings.term_text36}</strong><br /><br />
        {strings.term_text37} <br /><br />
        {strings.term_text38}<br /><br />
        <strong>{strings.term_text39} </strong><br /><br />
        {strings.term_text40}         
                </div>
        </div>
        </div>
        </section>
        <Footer />

        </React.Fragment>);
        }
    }

export default Term;