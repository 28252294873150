import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import API,{axiosInstance1 as API1} from "../api.js";
import strings from '../lang/index';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Modal from 'react-bootstrap/Modal';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            coupons:[],
            currentPage:1,
            total:0,
            catArr:[],
            dealArr:[],
            userId:(localStorage.getItem('user_id'))?localStorage.getItem('user_id'):"1",
            cat:'all',
            storeLoader:true,
            totalPages:0,
            loadAffFlag:false,
            store:this.props.coupon,
            snackStatus:false,
            modal:false,
            snackMessage:"",
            websiteName:"",
            websiteLogo:"",
            couponDetail:{},
            website_id:0,
            hasAffLink:false,
            percentage:'8',
        }
    }
    componentDidMount() {
        
        this.loadCoupon(this.props.coupon,1);
    }


    reportCoupon = (data) => {
      this.handleModalClose();
      confirmAlert({
        title: strings.report_coupon,
        message: strings.are_you_sure_you_want_to_report_this_coupon_not_working,
        buttons: [
          {
            label: strings.yes,
            onClick: () => {this.reportCouponApi()}
          },
          {
            label: strings.no,
            onClick: () => {}
          }
        ]
      });
    };

    loadAff = () =>{
      this.setState({loadAffFlag:true});
      API1({
        method: 'GET',
        url: "apply_coupon?user_id="+this.state.userId+"&shop="+this.state.store,
      }).then(data => {
        this.setState({loadAffFlag:false});
        if ('url' in data.data) {
          let url = "";
          if (data.data.url) {
            url = data.data.url;
          }else{
            url = data.data.homepage_url;
          }
          let win = window.open(url, '_blank');
          win.focus();
        }
        //this.setState({cat:'featured',storeLoader:false,sites:data.data.websites,total:data.data.total_websites,totalPages:data.data.total_pages});
      });
    }

    openDeal = (url) =>{
      let win = window.open(url, '_blank');
      win.focus();
    }

    reportCouponApi = () =>{
        API({
          method: 'POST',
          url: "/coupons/report",
          data:{"coupon_id": this.state.couponDetail.coupon_id, "user_id" : parseInt(this.state.userId)}
        }).then(data => {
            
            this.setState({snackStatus:true,snackMessage:"Coupon Reported!"});
          
        });
    }

    loadCoupon = (type,page) =>{
        API({
          method: 'GET',
          url: "coupons/all?hostname="+type,
        }).then(data => {
            if ('error' in data.data || 'tracking_url' in data.data) {    
               // this.setState({store:this.props.coupon,storeLoader:false,coupons:[],total:0,totalPages:1});
            }else{
                this.setState({website_id:data.data.website_id,websiteName:data.data.website_name,websiteLogo:data.data.website_logo,store:data.data.website_domain,storeLoader:false,coupons:data.data.coupons,total:data.data.coupons.length,totalPages:1});
                this.loadDeals(data.data.website_id);
                this.hasAffLink(data.data.website_id);            
            }
          
        });
    }

    loadDeals = (id) =>{
        API({
          method: 'GET',
          url: "deals_from_website?user_id="+this.state.userId+"&website_id="+id,
        }).then(data => {
            if ('error' in data.data || 'tracking_url' in data.data) {    
               // this.setState({store:this.props.coupon,storeLoader:false,coupons:[],total:0,totalPages:1});
            }else{
                this.setState({dealArr:data.data.deals});            
            }
          
        });
    }

    hasAffLink = (id) =>{
      API1({
        method: 'GET',
        url: "apply_coupon?user_id="+this.state.userId+"&shop="+this.state.store,
      }).then(data => {
        if ('url' in data.data && 'cashback' in data.data && data.data.url && data.data.cashback !== null) {
          this.setState({hasAffLink:true,percentage:data.data.cashback });
        }
      });
       
    }

    copyAndVisit = () =>{
      this.copyCoupon(this.state.couponDetail.code);
      this.loadAff();
    }


    copyCoupon = (code) =>{
        const el = document.createElement('textarea');
        el.value = code;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.setState({snackStatus:true,snackMessage:"Coupon Copied!"});
    }

    handleClose = () =>{
        this.setState({snackStatus:false});
    }

    handleModalClose = () =>{
    	this.setState({modal:!this.state.modal});
    }

   openCouponModal = (data) => {
      console.log(data)
      this.setState({modal:true,couponDetail:data});
   }

 render(){
   
	return (
   <React.Fragment>
    <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.snackStatus}
        autoHideDuration={3000}
        onClose={this.handleClose}
        message={this.state.snackMessage}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={this.handleClose}>
              <i class="fa fa-cross" ></i>
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
              <i class="fa fa-cross"></i>
            </IconButton>
          </React.Fragment>
        }
      />
   	<Modal
        show={this.state.modal}
        onHide={() => this.handleModalClose()}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        
        <Modal.Body>
        <button onClick={(e) => {this.handleModalClose()}} type="button" class="close"><span aria-hidden="true">×</span><span class="sr-only">{strings.close}</span></button>
          <img className="coupon-modal-img" src={"data:image/png;base64,"+this.state.websiteLogo} />
          <p >
            {this.state.couponDetail.title}
          </p>
          <div className="coupon-modal-box">
              <p className="coupon-modal-head">{strings.coupon_code}</p>
              <p className="coupon-code-modal">{this.state.couponDetail.code}</p>
          </div>
          {this.state.couponDetail.min_spend_value === 0 && <p className="text-danger m-2"></p>}
          
      {this.state.couponDetail.min_spend_value !== 0 && <p className="text-danger m-2">{strings.on_minimum_purchase_of_aed}{this.state.couponDetail.min_spend_value}</p>}
          <button onClick={(e) =>{this.copyAndVisit()}} className="copybtn-coupon-modal">{(this.state.loadAffFlag)?strings.please_wait:strings.copy_code_and_visit_site}</button>
          {this.state.couponDetail.coupon_terms && <p className="mt-2">{strings.coupon_terms}</p>}
          <div className="coupon-modal-desc">
          <p className="coupon-modal-instruction">{this.state.couponDetail.coupon_terms}</p>
          {this.state.couponDetail.expiry_date  && <p className=" m-2">{strings.expires_on}{this.state.couponDetail.expiry_date.replace("00:00:00 GMT",".")}</p>}
          </div>
          <hr className="hr-coupon-modal" />
          <p>{strings.how_do_coupon_works}</p>
      <p className="coupon-modal-instruction">{strings.coupon_works_desc}</p>
      <a href="javascript:void(0);" className="text-danger report-coupon-modal" onClick={(e)=>{this.reportCoupon(this.state.couponDetail)}}>{strings.report_cpupon_title}</a>
        </Modal.Body>
      </Modal>
		<div class="o-children">
  <div class="headerContentWrapper">
    <div class="imageContainer-banner">
      <div class="overlay-featured"></div>
      <picture><img alt="Brand Image" class="t-loading t-load image-featured" title="" src="https://cdn.joinhoney.com/images/storefront/fallback4.jpg" /></picture>
    </div>
    <div class="maxWidthRelativeWrapper">
      <div class="logoWrapper">
        <div class="imageContainer-dp">
          <picture><img alt="Brand Logo" class="t-loading t-load image-featuredDP" title="" src={"data:image/png;base64,"+this.state.websiteLogo} /></picture>
        </div>
        <div class="wrapper-featured">
          <div class="details-featured">
            <div class="nameFeatured h2" title="Brand">{this.state.websiteName}</div>
            <div class="flexWrapper">
              <div class="text body3" title="Medial Devices &amp; Supplies"> ·</div>
              <div class="text body3 moreInfo" title="More Info"></div>
            </div>
          </div>
          <div class="container-featured">
            <div class="buttonContainer">
              <button aria-label="Follow" onClick={(e)=>{this.loadAff()}} type="submit" class="btn-follow secondary-follow flexible">
                <div class="btnContentContainer" >
                                  <div class="btnCopyFollow" >{(this.state.loadAffFlag)?strings.please_wait:strings.official_site}</div>
                                </div>
              </button>
            </div>
            <div class="buttonContainer">
              {/*<button aria-label="Official Site" type="submit" class="btn-officialSite third-officialSite flexible">
                <div class="btnContentContainer">
                  <div class="btnCopyofficial" onClick={(e)=>{this.loadAff()}}>{(this.state.loadAffFlag)?strings.wait_text:strings.official_site}</div>
                </div>
              </button>*/}
            </div>
          </div>
        </div>
      </div>
      {/*<div class="average-savings-btn">
        <div class="as-btn">
          <span class="text-as title0as" title="Average savings: $8.85">Average savings: $8.85</span>
        </div>
        <div class="as-btn">
          <span class="text-as title0as" title="Best Discount: 15%">Best Discount: 15%</span>
        </div>
      </div> */}     
    </div>
  </div>
  
  <div class="container-0-2-157 container-0-0-2-161">
    <div class="tabs-0-2-160 paddingBox-0-2-158">
      <section class="featured" id="featured">
        <ul class="nav nav-tabs" role="tablist">
          
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#couponsDeals" role="tab">{strings.coupons_a_deals}</a>
          </li>
        </ul>
      </section>      
    </div>
    
    <div class="container-0-2-176">
      
      <div class="tab-content">
        <div class="tab-pane active" id="featured" role="tabpanel">             
          <div class="root-0-2-178 root-0-2-177">
          {this.state.hasAffLink && <React.Fragment><div class="couponList"><div class="hasSummaryBgImage-0-2-216 couponCardRoot coupon-card-root">
              <div class="summaryContainer-0-2-192 summary-container">
                <picture><img alt="Honey Gold" class="t-loading-0-2-125 t-load-0-2-126" title="" src="/images/coin-stack.png" /></picture>
              </div>
              <div class="copyContainer-0-2-188">
                <div class="couponTitle-0-2-191 title2">
                  <span>&nbsp; 
                  <span>
                  <div class="ttElement-0-2-218">
                    <div class="t-container-0-2-219">
                      <div class="t-anchorTextElement-0-2-221 t-anchorTextElementDotted-0-2-222">
                        <span style={{color: 'rgb(207, 153, 37)', fontWeight: '700', cursor: 'default'}}>Get upto {this.state.percentage}% cashback with Helwa</span>
                      </div>
                      
                    </div>
                  </div>
                  </span>&nbsp; 
                  </span>
                </div>
                <div class="couponDescription-0-2-190 body3">{strings.earn_helwa_text}</div>
              </div>
              <div class="actionButtonContainer-0-2-187">
                <button aria-label="Activate Offer" type="submit" class="btn-0-2-29 btn-15-0-2-228 primary-0-2-31 primary-16-0-2-229">
                  <div class="btnContentContainer-0-2-28">
                    <div class="btnCopy-0-2-36" onClick={(e)=>{this.loadAff()}} >{(this.state.loadAffFlag)?strings.wait_text:strings.activate_offer}</div>
                  </div>
                </button>
              </div>
              <div class="footerContainer-0-2-193">
                <span class="tag-0-2-194 title0 goldTag-0-2-215"> Get upto {this.state.percentage}% cashback with Helwa
 </span>
              </div>
            </div></div></React.Fragment>}
          
            <div class="titleRow-0-2-180">
              <div class="title-0-2-181 title4">{strings.coupons} ({this.state.coupons.length})</div>
            </div>
            
            {this.state.coupons.length > 0 && this.state.coupons.map((k)=>{
               return <div class="couponList">
                          <div class="couponCardRoot coupon-card-root">
                            <div class="summaryContainer-0-2-192 summary-container">
                              <div class="icon-container">
                                <div class="outer-0-2-197 outer-0-0-2-199">
                                  <div class="inner-0-2-198"> 
                                    <picture><img alt="coupon ticket" class="t-loading-0-2-125 t-load-0-2-126" src={"data:image/png;base64,"+this.state.websiteLogo}  title="" /></picture>
                                  </div>
                                </div>
                              </div>
                              <div class="icon-container-title price2">{k.shop_name}</div>
                            </div>
                            <div class="copyContainer-0-2-188">
                              <div class="couponTitle-0-2-191 title2">{k.title}</div>
                              <div class="couponDescription-0-2-190 body3">{k.min_spend_value != "" && k.min_spend_value !== 0 && "{strings.on_mininum_spend_of} "+k.min_spend_value}</div>
                            </div>
                            <div class="actionButtonContainer-0-2-187">
                              <div class="main-0-2-207">
                                <button class="btn-0-2-208">
                                  <div class="copy-0-2-209" onClick={(e) =>{this.openCouponModal(k)}}>{strings.get_coupon}</div>
                                </button>
                                <div class="corner-0-2-210"></div>
                              </div>
                            </div>
                            <div class="footerContainer-0-2-193">
                              <span class="tag-0-2-194 title0 tagInList-0-2-212 showAtDesktop-0-2-211 tagWarning-0-2-195">
                                <picture><img alt="helwa logo  circle with background color" class="t-loading-0-2-125 t-load-0-2-126 coupon-helwa-img"  title="" src="/images/favicon.ico" /></picture>
                                {/*<span class="title0">Helwa Exclusive</span>*/}
                              </span>
                              {/*<span class="tag-0-2-194 title0 tagInList-0-2-212 showAtDesktop-0-2-211"> {k.category_name}</span>*/}
                            </div>
                          </div>
                        </div>
                      })}
            
          </div>

          <div class="root-0-2-178 root-0-2-177" style={{paddingTop:'0px'}}>
            {this.state.dealArr.length > 0 && <div class="titleRow-0-2-180">
                          <div class="title-0-2-181 title4">{strings.deals} ({this.state.dealArr.length})</div>
                        </div>}
            
            {this.state.dealArr.length > 0 && this.state.dealArr.map((k)=>{
               return <div class="hasSummaryBgImage-0-2-216 couponCardRoot coupon-card-root">
                      <div class="icon-container">
                        <div class="outer-0-2-197 outer-0-0-2-199">
                          <div class="inner-0-2-198"> 
                            <picture><img alt="coupon ticket" style={{margin: '40px auto'}} class="t-loading-0-2-125 t-load-0-2-126" src={"data:image/png;base64,"+this.state.websiteLogo}  title="" /></picture>
                          </div>
                        </div>
                      </div>
                      <div class="copyContainer-0-2-188">
                        <div class="couponTitle-0-2-191 title2">
                          <span> 
                          <span>
                          <div class="ttElement-0-2-218">
                            <div class="t-container-0-2-219">
                              <div class="t-anchorTextElement-0-2-221 t-anchorTextElementDotted-0-2-222">
                                
                              </div>
                              
                            </div>
                          </div>
                          </span>{k.deal_headline}
                          </span>
                        </div>
                        <div class="couponDescription-0-2-190 body3">{k.deal_details}</div>
                        <div className="deal-expires">{strings.expires_on} {k.deal_expiry_date.replace("00:00:00 GMT",".")}</div>

                      </div>
                      <div class="actionButtonContainer-0-2-187">
                        <button aria-label="Activate Offer" type="submit" class="btn-0-2-29 btn-15-0-2-228 primary-0-2-31 primary-16-0-2-229">
                          <div class="btnContentContainer-0-2-28">
                            <div class="btnCopy-0-2-36" onClick={(e)=>this.openDeal(k.deal_aff_link)}>{strings.activate_deal}</div>
                          </div>
                        </button>
                      </div>
                      <div class="footerContainer-0-2-193">
                        <span><picture><img alt="helwa logo  circle with background color " class="t-loading-0-2-125 t-load-0-2-126 coupon-helwa-img"  title="" src="/images/favicon.ico" /></picture>      
                          </span>
                      </div>
                    </div>
                      })}
            
          </div>
          
        </div>
        
        
        
        
      </div>
          
    </div>
    
  </div>

</div>
</React.Fragment>
	);
}
}

export default Header;