import React from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../component/header';
import Footer from '../component/footer';
import strings from '../lang/index';
class Login extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          login:false
      }
  }
  componentDidMount() {
      setTimeout(() => { 
        this.setState({login:true}); 
      }, 5000);
  }
  
 render(){
  if (this.state.login) {
    if (localStorage.getItem('name')) {
      window.location.href = "https://helwa.io/installed";
    }else{
      window.location.href = "https://helwa.io/login";
    }
     
  }
  return (
    <React.Fragment>
      <Header />
        <section class="banner">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-6 bannerleft my-auto">
                <h1 class="mb-3">{strings.installed_text1}</h1>
                <p>{strings.installed_text2}</p>
                <img src="https://omd.postaffiliatepro.com/scripts/a4novgsvn?AccountId=8a6bcaf3&InstallID=(Variable for InstallId)&ProductID=Helwa" width="1" height="1" />
                
                
              </div>
          </div>
          </div>
        </section>
      <Footer />
    </React.Fragment>);
}
}
export default Login;