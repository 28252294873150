/*global chrome*/
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import strings from '../lang/index';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homePage:false,
            login:false,
            name:'',
            imageUrl:localStorage.getItem('pic'),
            menuFlag:false,
            lang:(localStorage.getItem('lang'))?localStorage.getItem('lang'):"en"
        }
    }

    sendLoginMessage = (response) => {
        var editorExtensionId = "fipaemkffbblojagehfkanmbhpbonppd";//"pafanodhjjegdklakogpmbdioodgkngp";
        if (typeof chrome !== "undefined" && chrome.runtime) {
            chrome.runtime.sendMessage(editorExtensionId, {cmd:'login',response: response},
                function(response) {
                    if (!response.success)
                        console.error("message to extension fail");
                }
            );
        }
    }

    openMenu = () =>{
        this.setState({menuFlag:!this.state.menuFlag});
    }

    changeLang = (e,lang) => {
        localStorage.setItem('lang',lang);
        window.location.href = window.location.href.split('?')[0];
    }

    handleClose = ()=>{
        this.setState({menuFlag:false});
    }

    handleLogout = () =>{
        this.setState({menuFlag:false});
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('id');
        localStorage.removeItem('user_id');
        localStorage.removeItem('token');
        localStorage.setItem('loggedOut',1);
        window.location.reload();
    }

    componentDidMount() {
        let homePage = false;
        let login= false;
        let name = '';
        if(window.location.pathname === '/' || window.location.pathname === '/installed'){
          homePage = true;
        }
        let lang = new URLSearchParams(window.location.search).get('lang');
        if (lang) {
            if (localStorage.getItem('lang') != lang) {
                localStorage.setItem('lang',lang);
                window.location.reload();    
            }
                   
        }

        if (localStorage.getItem('name')) {
            login = true;
            name = localStorage.getItem('name');
            this.sendLoginMessage({'email': localStorage.getItem('email'), 'name': localStorage.getItem('name'), 'total_coins': localStorage.getItem('coins'), 'user_id': localStorage.getItem('user_id'),
                'profile_pic': localStorage.getItem('pic'), 'token':localStorage.getItem('token') });
            
        }
        this.setState({homePage:homePage,login:login,name:name});
    }
 render(){
	return (
		<header class="bsnav bsnav-sticky bsnav-sticky-slide">

                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">   
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <Link class="navbar-brand" to="/"><img src="/images/helwa_logo.svg" alt="logo" /></Link>
                        {<React.Fragment><button class="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span></button>
    
                          <div class="collapse navbar-collapse main-menu-item justify-content-center" id="navbarSupportedContent">
                                <ul class="navbar-nav align-items-center ml-auto">
                                    <li class={(this.props.page === "works")?"nav-item active":"nav-item"}>
                                        <Link to="/how-works" class="nav-link" >{strings.how_does_works}</Link>
                                    </li>
                                    <li class={(this.props.page === "store")?"nav-item active":"nav-item"}>
                                        <Link to="/stores" class="nav-link" >{strings.online_store_list}</Link>
                                    </li>
                                    <li class={(this.props.page === "faq")?"nav-item active":"nav-item"}>
                                        <Link to="/faq" class="nav-link" >{strings.faq}</Link>
                                    </li>
                                    <li class={(this.props.page === "refer")?"nav-item active":"nav-item"}>
                                        <Link to="/refer" class="nav-link">{strings.refer_title}</Link>
                                    </li>
                                    <li class={(this.props.page === "contact")?"nav-item active":"nav-item"}>
                                        <Link to="/contact" class="nav-link">{strings.contact_us}</Link>
                                    </li>
                                    <li class="nav-item">
                                        {this.state.lang === 'en' && <a class="nav-link" href="javascript:void(0)" onClick={(e) => this.changeLang(e,"ar")} >بالعربية</a>}
                                        {this.state.lang === 'ar' && <a class="nav-link" href="javascript:void(0)" onClick={(e) => this.changeLang(e,"en")} >English</a>}
                                    
                                    </li>
                                </ul>
                                {/* <a href="javascript:void(0);"  className="nameText" >{strings.hi} {this.state.name}</a>*/}
                                <div class="registerbtn ml-auto">                                
                                    {!this.state.login && window.location.pathname !== '/' && <Link to="/login">{strings.register}</Link>}
                                    {!this.state.login && window.location.pathname === '/' && <a href="https://chrome.google.com/webstore/detail/helwa-automatic-coupon-fi/fipaemkffbblojagehfkanmbhpbonppd?authuser=0&hl=en">{strings.get_helwa}</a>}
                                    {this.state.login && <React.Fragment>
                                        <Avatar onClick={this.openMenu} className="nameText" aria-controls="simple-menu" aria-haspopup="true" alt={this.state.name} src={this.state.imageUrl} >{this.state.name.charAt(0).toUpperCase()}</Avatar>
                                        <Menu
                                        id="simple-menu"
                                        anchorEl={this.state.menuFlag}
                                        keepMounted
                                        className= "topLoginMenue"
                                        open={this.state.menuFlag}
                                        onClose={this.handleClose}
                                      >
                                        <MenuItem>Signed in as {this.state.name}</MenuItem>
                                        <Link to="/helwapoint" className="black-color"><MenuItem>{strings.HELWA_POINTS} &nbsp;&nbsp;<i class="fa fa-angle-right"></i></MenuItem></Link>
                                        <MenuItem onClick={this.handleLogout}><i class="fa fa-sign-out" aria-hidden="true"></i> &nbsp;{strings.Logout}</MenuItem>
                                      </Menu>
                                      </React.Fragment>

                                    }

                                    {false && <a href="https://chrome.google.com/webstore/detail/helwa-automatic-coupon-fi/fipaemkffbblojagehfkanmbhpbonppd?authuser=0&hl=en">{strings.get_helwa}</a>}                       
                                    
                                </div>
                                {this.state.login && <React.Fragment>&nbsp;<i onClick={this.openMenu} class="fa fa-caret-down down-arrow" aria-hidden="true"></i></React.Fragment>}
                                
                         
                          </div></React.Fragment>}
                    </nav>
                </div>
            </div>
        </div>
  </header>
	);
}
}

export default Header;