import React from 'react';
import { Link } from 'react-router-dom';
import strings from '../lang/index';
function Header(props) {
	return (
		<section class="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-12 text-lg-left text-center">
                <div class="footerlogo"><Link to="/"><img src="/images/helwa_logo.svg" alt="logo" /></Link></div>
                <p>&copy; {new Date().getFullYear()} {strings.right_reserved}</p>
                
                <div class="footerlinks">
                    <Link to="/privacypolicy.html">{strings.privacy_policy}</Link>
                    <span class="footer__links__divider"></span>
                    <Link to="/terms.html">{strings.terms_conditions}</Link>
                </div>
                
            </div>
            
            <div class="col-lg-4 col-sm-6 col-10 offset-lg-0 offset-sm-3 offset-1 text-lg-left text-center">
                <div class="row">
                    <div class="col-lg-5 col-6">
                        <h5>{strings.company_text}</h5>
                        <ul>
                            <li><Link to="/privacypolicy.html">{strings.privacy_policy}</Link></li>
                            <li><Link to="/terms.html">{strings.terms_conditions}</Link></li>
                            <li><a href="mailto:admin@helwa.io">{strings.contact_us}</a></li>
                        </ul>
                    </div>
                    
                </div>
            </div>
            
            <div class="col-lg-2 offset-lg-1 text-center text-lg-left footer__sociable">
                <a href="https://apps.apple.com/us/app/id1519569679"><img src="/images/appstoreicon.jpg" alt="" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.app.helwa"><img src="/images/googleplay.jpg" alt="" /></a>
                <ul class="socialfoot">
                    <li><a href="https://www.facebook.com/gethelwa/" target="_blank"><i class="fa fa-facebook"></i></a></li>
                    <li><a href="https://www.instagram.com/gethelwa/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                </ul>
            </div>
        </div>
        <div class="footer-disclaimer">
            <p>{strings.helwa_footer1}</p>
            <p>{strings.helwa_footer2}</p>
            <p style={{color: 'black'}}>{strings.made_with_love_in_uae}</p>
            {false && <p style={{color: 'black'}}>{strings.made_with} <span style={{color: "red"}}>❤</span> {strings.in_uae}</p>}
        </div>
    </div>
</section>
	);
}

export default Header;