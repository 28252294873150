import React from 'react';
import Home from './page/Home';
import HomeInstalled from './page/HomeInstalled';
import Term from './page/Term';
import Privacy from './page/Privacy';
import Report from './page/Report';
import Feedback from './page/Feedback';
import Stores from './page/Stores';
import Coupon from './page/Coupon';
import CashbackTerms from './page/CashbackTerms';
import Contact from './page/Contact';
import Login from './page/Login';
import LoginEmail from './page/LoginEmail';
import Forgot from './page/Forgot';
import Reset from './page/Reset';
import Refer from './page/Refer';
import HowWorks from './page/HowWorks';
import EmailVerified from './page/EmailVerified';
import Faq from './page/Faq';
import AffLinkLoad from './page/AffLinkLoad';
import HelwaPoint from './page/HelwaPoint';
import qrdetect from './page/Qrdetect';
import ExtensionInstalled from './page/ExtensionInstalled';
import ExtensionUninstalled from './page/ExtensionUninstalled';

import { Route, Switch } from 'react-router-dom';

function App() {
    return ( < Switch >

        <
        Route path = "/installed"
        component = { HomeInstalled }
        exact / >
        <
        Route path = "/qrdetect.html"
        component = { qrdetect }
        exact / >
        <
        Route path = "/uninstalled"
        component = { ExtensionUninstalled }
        exact / >
        <
        Route path = "/terms.html"
        component = { Term }
        /> <
        Route path = "/privacypolicy.html"
        component = { Privacy }
        /> <
        Route path = "/report"
        component = { Report }
        /> <
        Route path = "/feedback"
        component = { Feedback }
        /> <
        Route path = "/Contact"
        component = { Contact }
        /> <
        Route path = "/login"
        component = { Login }
        /> <
        Route path = "/login-email"
        component = { LoginEmail }
        /> <
        Route path = "/forgot"
        component = { Forgot }
        /> <
        Route path = "/extension-installed"
        component = { ExtensionInstalled }
        /> <
        Route path = "/stores/:store?"
        component = { Stores }
        /> <
        Route path = "/coupon/:name"
        component = { Coupon }
        /> <
        Route path = "/affLink/:name"
        component = { AffLinkLoad }
        /> <
        Route path = "/helwapoint"
        component = { HelwaPoint }
        /> <
        Route path = "/refer"
        component = { Refer }
        /> <
        Route path = "/cashback-terms"
        component = { CashbackTerms }
        /> <
        Route path = "/how-works"
        component = { HowWorks }
        /> <
        Route path = "/faq"
        component = { Faq }
        /> <
        Route path = "/reset"
        component = { Reset }
        /> <
        Route path = "/email-verified/:status"
        component = { EmailVerified }
        /> <
        Route path = "/:page?"
        component = { Home }
        exact / > < /Switch>
    );
}

export default App;