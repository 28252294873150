import React from 'react';
import { Link } from 'react-router-dom';
import API,{axiosInstance1 as API1} from "../api.js";
import Slider from "react-slick";
import strings from '../lang/index';
import InfiniteScroll from 'react-infinite-scroller';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    ><img src="/images/next-slick.png" /></div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    ><img src="/images/prev-slick.png" /></div>
  );
}

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sites:[],
            currentPage:1,
            total:0,
            catArr:[],
            featureArr:[],
            totalSites:false,
            cat:'',
            userId:(localStorage.getItem('user_id'))?localStorage.getItem('user_id'):"1",
            storeLoader:true,
            totalPages:1,
            loadAffFlag:false,
            loaded:false,
            loader:false,
            search:'',
            searchSites:[],
            noResultSearch:false
        }
    }
    componentDidMount() {
        API({
          method: 'GET',
          url: "websites?cat=all&page=1",
        }).then(data => {
          this.setState({totalSites:data.data.total_websites});
        });
        this.loadCat();
        this.loadFeatured();
        if (this.props.cat === undefined) {
            this.loadFeatureShop(1)
            //this.loadShop('all',1);    
        }else{
            this.changeCat('e',this.props.cat);
        }
        
    }

    handleChangeSearch = (event) => {
        const { name, value } = event.target;
        
        this.setState({[name]:value});
    }

    searchShop = (e) =>{
        API({
          method: 'GET',
          url: `v2/websites/search?user_id=1&website_name=${this.state.search}&page=1`,
        }).then(data => {
            console.log(data.data)
            if ('websites' in data.data) {
                let noResultSearch=false;
                if(data.data.websites.length ==0){
                    noResultSearch=true
                }
                this.setState({searchSites:[...data.data.websites],noResultSearch});             
            }
          
        });
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.loadMore);
    }


    loadAff = (shop) =>{
      let win = window.open('/affLink/'+shop, '_blank');
        win.focus();
    }

    changePage = (e,page) => {
        this.setState({currentPage:page,sites:[],storeLoader:true})
        if (this.state.cat === 'featured') {
            this.loadFeatureShop(page)
        }else{
            this.loadShop(this.state.cat,page);   
        }
    }

    loadShop = (type,page) =>{
        API({
          method: 'GET',
          url: "websites?cat="+type+"&page="+page,
        }).then(data => {
            if ('websites' in data.data) {
                this.setState({cat:type,storeLoader:false,sites:[...this.state.sites,...data.data.websites],total:data.data.total_websites,totalPages:data.data.total_pages,loaded:true,currentPage:this.state.currentPage+1});             
            }
          
        });
    }

    loadFeatureShop = (page) =>{
        API({
          method: 'GET',
          url: "featured/get?paginate=true&page="+page,
        }).then(data => {
            if ('websites' in data.data) {
                this.setState({cat:'featured',storeLoader:false,sites:[...this.state.sites,...data.data.websites],total:data.data.total_websites,totalPages:data.data.total_pages,loaded:true,currentPage:this.state.currentPage+1});
            }
        });
    }

    changeCat = (e,cat) => {
        this.setState({cat:cat,sites:[],storeLoader:true,currentPage:1});
        if (cat === 'featured') {
        	this.loadFeatureShop(1)
        }else{
        	this.loadShop(cat,1);	
        }
        document.getElementById('shopList').scrollIntoView(true);
        setTimeout(() => {
          window.scrollBy(0, -80);
        }, 500);
        

    }

    loadCat = () =>{
        API({
          method: 'GET',
          url: "categories",
        }).then(data => {
          this.setState({catArr:data.data});
        });   
    }
    loadFunc = () =>{
        if (this.state.totalPages <= this.state.currentPage) {
            return false;
        }
        if (this.state.cat === 'featured') {
            this.loadFeatureShop(this.state.currentPage+1)
        }else{
            this.loadShop(this.state.cat,this.state.currentPage+1);   
        }
        
    }

    loadFeatured = () =>{
        API({
          method: 'GET',
          url: "featured_custom/all?user_id="+this.state.userId,
        }).then(data => {
            //alert(data.data.featured.length);
            console.log(data)
          this.setState({featureArr:data.data.featured_promotions});
        });   
    }
 render(){
     let settings = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 2,
         nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
        {
          breakpoint: 600,
          settings: {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    console.log(this.state.searchSites.length);
	return (
        
		<section class="storegrid" id="onlinestore">
    <div class="container">
        <div class="row  justify-content-center">

            <div class="col-lg-8">
                <h3>{strings.shoplist_txt1}</h3>
                <h4>{strings.shoplist_txt2} <a href="mailto:admin@helwa.io">admin@helwa.io</a>  {strings.shoplist_txt3}</h4>
            </div>
        </div>
        
        <section class="section-content padding-y">
            <div class="row margin-row-0">
            <div className='col-md-12' style={{marginBottom: '15px'}}>
                <input type="text" placeholder='Search Shop(s)' value={this.state.search} name="search" onChange={this.handleChangeSearch} class="shop-search-input"/>
                <button className="btn-follow secondary-follow serach-btn" onClick={(e) => this.searchShop(e)}>Search</button>
                <div className="row" style={{marginTop: '25px'}}>
                    {this.state.noResultSearch && <p style={{margin: 'auto'}}>No Results found</p> }                   
                            {this.state.searchSites.length > 0 && this.state.searchSites.map((k)=>{
                                let hostname= "";
                                let logo = k.website_image;
                                if ('website_home' in k) {
                                    
                                    let url = new URL(k.website_home);
                                    hostname = url.hostname.replace('www.','')
                                    hostname = (hostname.split('.').length ==2)?hostname:hostname.split('.')[1]+'.'+hostname.split('.')[2]    
                                    //logo = k.logo;
                                }else{
                                    //logo = k.s3_logo;
                                    hostname = k.shop_url;
                                }
                                
                               return <div class="col-md-4">
                                <Link to={"/coupon/"+ hostname} >
                                <figure class="card card-product-grid">
                                    <div class="img-wrap"> 
                                        <img className="center-img" src={logo} />
                                        <h4 className="shop-title">{k.website_name}</h4>                      
                                    </div>                  
                                </figure>
                                </Link>
                            </div>
            
                            })}         
                        </div>
                </div>
                <div className="col-md-12">
                {this.state.featureArr.length > 0 && <h2 class="cat-img-title">Featured</h2>}
                    <Slider classNameName=" regular02 slider" {...settings}>
                      {this.state.featureArr.length > 0 && this.state.featureArr.map((k)=>{
                          return (<React.Fragment><div className="cat-list-div">
                                <a onClick={(e)=>{this.loadAff(k.website_domain);}}><img  className="featured-img-main" src={k.s3_logo} /></a>
                                <figure class="card card-product-grid feature-figure">
                                    <div class="img-wrap"> 
                                        <img className="center-img" src={k.s3_logo} />                      
                                    </div>                  
                                </figure>
                                {k.has_cashback && <p className="cb-text-featured">{k.cashback_text}</p>}
                                
                            </div>
                            <h6 className="featured_headline_txt">{k.featured_headline}</h6>
                            <h6>{k.featured_details}</h6>
                            </React.Fragment>
                            );
                        })}
                    </Slider>
                </div>
                
                <div className="col-md-12">
                <h2 class="cat-img-title">Discover By Category</h2>
                    <Slider classNameName="testiContent regular02 slider" {...settings}>
                      {this.state.catArr.length > 0 && this.state.catArr.map((k)=>{
                          return (<div className="cat-list-div">
                                <img onClick={(e) => this.changeCat(e,k.name)} className="cat-img" src={'data:image/png;base64,'+k.image} />
                                <p className="cat-div-text">{k.name}</p>
                            </div>);
                        })}
                    </Slider>
                </div>
                </div>
                <div class="row margin-row-0" >
                
                <aside class="col-md-3 mt-5">
                    
            <div class="card">
                <article class="filter-group">
                    <header class="card-header">
                        <a href="#" data-toggle="collapse" data-target="#collapse_1" aria-expanded="true" class="">
                            <i class="icon-control fa fa-chevron-down"></i>
                            <h6 class="title">{strings.cat}</h6>
                        </a>
                    </header>
                    <div class="filter-content collapse show" id="collapse_1" >
                        <div class="card-body text-center">
                        
                            <ul class="list-menu">
                            {this.state.catArr.length > 0 && <li onClick={(e) => this.changeCat(e,'featured')} className={(this.state.cat === 'featured')?"active-cat":""} ><a>{strings.featured_text}</a></li>}
                            {this.state.catArr.length > 0 && <li onClick={(e) => this.changeCat(e,'all')} className={(this.state.cat === 'all')?"active-cat":""} ><a>{strings.all}</a></li>}
                            {this.state.catArr.length > 0 && this.state.catArr.map((k)=>{
                               return <li onClick={(e) => this.changeCat(e,k.name)} className={(this.state.cat === k.name)?"active-cat":""} ><a >{k.name}</a></li>;  
                            })}
                            
                            </ul>
            
                        </div>
                    </div>
                </article>           
                
            </div>
            
                </aside>
                <main class="col-md-9 mt-5" id="shopList">
                
            <header class="border-bottom mb-4 pb-3">
                    <div class="form-inline">
                        <span class="mr-md-auto"> {this.state.totalSites && "Helwa now supports " + this.state.totalSites+' Middle East stores'}  </span>
                    </div>
            </header>
            {this.state.loaded && <InfiniteScroll
                            pageStart={0}
                            loadMore={this.loadFunc}
                            hasMore={true}
                            loader={this.state.sites.length !== 0 && <div className="loader" key={0}></div>}
                        >
                        <div className="row">
                            
                            {this.state.storeLoader && <div className="text-center col-md-12"></div>}
                            {!this.state.storeLoader && this.state.sites.length > 0 && this.state.sites.map((k)=>{
                                let hostname= "";
                                let logo = k.s3_logo;
                                if ('home_url' in k) {
                                    
                                    let url = new URL(k.home_url);
                                    hostname = url.hostname.replace('www.','')
                                    hostname = (hostname.split('.').length ==2)?hostname:hostname.split('.')[1]+'.'+hostname.split('.')[2]    
                                    //logo = k.logo;
                                }else{
                                    //logo = k.s3_logo;
                                    hostname = k.shop_url;
                                }
                                
                               return <div class="col-md-4">
                                <Link to={"/coupon/"+ hostname} >
                                <figure class="card card-product-grid">
                                    <div class="img-wrap"> 
                                        <img className="center-img" src={logo} />
                                        <h4 className="shop-title">{k.name}</h4>                      
                                    </div>                  
                                </figure>
                                </Link>
                            </div>
            
                            })}
                        
                             
                        </div>
                         
                        </InfiniteScroll>}
            
            {!this.state.storeLoader && <nav class="mt-4" aria-label="Page navigation sample">
              {/*<ul class="pagination">
                              {this.state.currentPage != 1 && <li class="page-item"><a class="page-link" onClick={(e) => this.changePage(e,(this.state.currentPage - 1))} >{strings.previous}</a></li>}
                              {this.state.currentPage < this.state.totalPages && <li class="page-item"><a class="page-link" onClick={(e) => this.changePage(e,(this.state.currentPage + 1))} >{strings.next}</a></li>}
                            </ul>*/}
            </nav>}
            {(this.state.currentPage < this.state.totalPages || this.state.sites.length === 0) && <h4 className="mt-5">{strings.loading}</h4>}
            
                </main> 
            </div>
        </section>
        </div>
    </section>
	);
}
}

export default Header;