import React from 'react';
import { Link,Redirect } from 'react-router-dom';
import SocialButton from '../component/SocialButton';
import { GoogleLogin } from 'react-google-login';
import Header from '../component/header';
import Footer from '../component/footer';
import API from "../api.js";
import strings from '../lang/index';
import 'react-notifications/lib/notifications.css';
import SimpleReactValidator from 'simple-react-validator';
class Login extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          login:false,
          tab:1,
          email:"",
          otp:"",
          password:"",
          cpassword:"",
          loading:false,
          successMsg:""

      }
      this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
      let login = false;
      let tab = 1; 
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      let urlParams = new URLSearchParams(window.location.search);
      let myParam = urlParams.get('signup');
      if (parseInt(myParam) === 1) {
        tab = 2;
      }
      if (localStorage.getItem('name')) {
          login = true;

      }
      this.setState({login:login,tab:tab});
  }
  

  changeTab = (tab) =>{
    this.setState({tab:parseInt(tab),email:'',password:''});
    this.validator.hideMessages();
    this.forceUpdate();
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };



sendEmail = ()=>{
  if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return ;
  }
  this.setState({loading:true})
  let data = {'email': this.state.email};
  API({
    method: 'POST',
    url: "send_forgot_password",
    data: data
  }).then(data => {
    console.log(data);
    if ('error' in data.data) {
      this.setState({error:data.data.error,loading:false});
    }else{
      this.setState({email:'',loading:false, successMsg:'Reset password link successfully send to your email.'});
      //this.setState({login:true}); 
    }
  }).catch(error => {
      this.setState({error:"Something went wrong",loading:false});
  });
}


resetPassword = ()=>{
  if (!this.validator.allValid()) {
    this.validator.showMessages();
    this.forceUpdate();
    return ;
  }

  
}


 render(){
  if (this.state.login) {
     return <Redirect to='/'/>;
  }
  return (
    <React.Fragment>
    <Header />
      <section className="banner">
  <div className="container">
    <div className="row">
    
      <div className="col-lg-12 col-md-12">
      <div className="success-msg-login">{this.state.successMsg}</div>
        <div className="col-md-12 loginsection loginsection2">
        <div className="row">

          <div class="wrapper fadeInDown">
            
            <div id="formContent">
              <div class="fadeIn first">
                <img className="login-logo" src="/images/helwa_logo.svg" />
              </div>  
              {this.state.tab === 1 && <h2 className="active" > Forgot Password </h2>}
              {this.state.tab === 2 && <h2 className="active"> Reset Password </h2>}

             
              

              <div className="row">
              <div className="col-md-12"><span className="srv-validation-message">{this.state.error}</span></div>
              {this.state.tab ===1 && <React.Fragment>
                <div className="col-md-12">
                  <input 
                    type="text" 
                    value={this.state.email}
                    onChange={(e) => { this.changeInput(e)} }
                    class="fadeIn second" 
                    name="email"
                    placeholder="Email" />
                    {this.validator.message('Email', this.state.email, 'required|email')}
                </div>
                
                <div className="col-md-12">
                 <input type="submit" onClick={(e)=>this.sendEmail(e)} class="fadeIn fourth" value={(this.state.loading)?"loading...":"submit"} />
                  
                </div>
                </React.Fragment>}
              
                </div>
            
              <div id="formFooter">
                <Link class="underlineHover" to="/login-email" >Back</Link>
              </div>

            </div>
          </div>
        </div>
      </div>
        
        
      </div>
  </div>
  </div>
</section>
<Footer />
    </React.Fragment>);
}
}
export default Login;