import React from 'react';
import { Redirect } from 'react-router-dom';
import Header from '../component/header';
import Footer from '../component/footer';
import strings from '../lang/index';
import API from "../api.js";
import SimpleReactValidator from 'simple-react-validator';
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        lockSubmit:false,
        reason:"",
        thanks:false
        
    }
    this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
    
  }

  changeInput = (event) => {
    const { name, value } = event.target;
    this.setState({[name]:value});
  }
  submitForm = () =>{
    if (this.validator.allValid() && !this.state.lockSubmit) {
        this.setState({lockSubmit:true});
        let json = {
            "user_id": localStorage.getItem('user_id'),
            "title":'Uninstall Feedback',
            "text": this.state.reason,
            "name": (localStorage.getItem('name'))?localStorage.getItem('name'):"Unknown",
            "email": (localStorage.getItem('email'))?localStorage.getItem('email'):"Unknown",
            "platform": "Chrome Extension",
            "type": 0
        }
        API({
          method: 'POST',
          url: "add_feedback",
          data:json,
        }).then(data => {
          this.setState({lockSubmit:false,thanks:true});
        }).catch(() => {
            this.setState({lockSubmit:false});
        });
    } else {
        this.validator.showMessages();
        this.forceUpdate();
    }
}
  
 render(){
  
  return (
    <React.Fragment>
      <Header />
      <section class="banner">
            
            <div class="bg-contact100">
            <div class="container-contact100">
                <div class="wrap-contact100">
                  {this.state.thanks && <h2>Thank for your valuable feedback</h2>}
                                       {!this.state.thanks && <form  onSubmit={e => {e.preventDefault();}}  >   
                                        <div class="form-group row">
                                            <div class="col-12 m-0 p-0">
                                                <h1>
                                                    Why did you install Helwa?
                                                </h1>
                                            </div>
                                        </div> 

                                        <div class="form-group row">
                                            <div class="col-12 ml-3">
                                            <input class="form-check-input" type="radio" value="Not enough stores covered" name="reason" onChange={(e) =>this.changeInput(e)} id="flexRadioDefault1" />
                                            <label class="form-check-label" for="flexRadioDefault1">
                                              Not enough stores covered
                                            </label>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-12 ml-3">
                                            <input class="form-check-input" value="No savings" type="radio" name="reason" onChange={(e) =>this.changeInput(e)} id="flexRadioDefault2" />
                                            <label class="form-check-label" for="flexRadioDefault2">
                                                No savings
                                            </label>
                                            </div>
                                          </div>
                                          <div class="form-group row">
                                            <div class="col-12 ml-3">
                                            <input class="form-check-input" value = "Privacy concern" type="radio" name="reason" onChange={(e) =>this.changeInput(e)} id="flexRadioDefault3" />
                                            <label class="form-check-label" for="flexRadioDefault3">
                                                Privacy concern
                                            </label>
                                             </div>
                                          </div>
                                          <div class="form-group row">
                                            <div class="col-12 ml-3">
                                            <input class="form-check-input" value="It slows down my computer" type="radio" name="reason" onChange={(e) =>this.changeInput(e)} id="flexRadioDefault4" />
                                            <label class="form-check-label" for="flexRadioDefault4">
                                                It slows down my computer
                                            </label>
                                            </div>
                                          </div>
                                          <div class="form-group row">
                                            <div class="col-12 ml-3">
                                            <input class="form-check-input" value="Doesn't work" type="radio" name="reason" onChange={(e) =>this.changeInput(e)} id="flexRadioDefault5" />
                                            <label class="form-check-label" for="flexRadioDefault5">
                                                Doesn't work
                                            </label>
                                            </div>
                                          </div>
                                          {this.validator.message('Reason', this.state.reason, 'required')}


 
  <div class="form-group row">
    <div class="col-12 ml-0">
    <button  class="btn btn-primary" onClick={this.submitForm} >{(this.state.lockSubmit)?strings.wait_text:strings.submit_text}</button>
    </div>
  </div>
  </form>}

                                   
                
				</div>
            </div>
            </div>
        </section>
      <Footer />
    </React.Fragment>);
}
}
export default Login;