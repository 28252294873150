import React from 'react';
import { Link } from 'react-router-dom';
import strings from '../lang/index';
import API,{axiosInstance1 as API1} from "../api.js";
import {InlineShareButtons} from 'sharethis-reactjs';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
class HowWorks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogin: localStorage.getItem('user_id'),
            isCode:true,
            code:'',
            codeInp:'',
            userId:(localStorage.getItem('user_id'))?localStorage.getItem('user_id'):"1",
            codeform:false,
            snackStatus:false,
            error:'',
            message:'',
            snackMessage:"",
            email:""
        }
    }
    componentDidMount() {
        
        if (this.state.isLogin) {
            this.checkReferral();
        }else{
            this.initMessage();
        }
        
    }

    initMessage = ()=>{
        let message = "Check out Helwa - a great way to save on your online shopping. It automatically finds and applies the best coupon codes for the Middle East's top online stores. Get it at www.helwa.io";
        if (this.state.isCode) {
            message ="Check out Helwa - a great way to save on your online shopping. It automatically finds and applies the best coupon codes for the Middle East's top online stores. Get it at www.helwa.io and apply and use my referral code \""+this.state.code+"\" for more benifits ";
        }
        this.setState({message:message});
    }

    changeInput = (e) => {
        let { name, value } = e.target;
        this.setState({ [name] : value });
    };

    checkReferral(){
        API({
            method: 'GET',
            url: "get_referral_code?user_id="+this.state.isLogin,
        }).then(data => {

        if ('error' in data.data) {
          this.setState({isCode:false},()=>{this.initMessage();});
        }else{
            this.setState({isCode:true,code:data.data.referral_code},()=>{
                this.initMessage();
            });
        }

        });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({[name]:value});
    }

    sendInvitationPost = (id) =>{
        API({
          method: 'POST',
          url: "send_email",
          data:{"user_id": this.state.userId,"to": this.state.email,"referral_code": this.state.code}
        }).then(data => {
          if('msg' in data.data){
            this.setState({email:"",snackStatus:true,snackMessage: 'Invitation sended successfully!'});
          }
        });
    }

    copyCoupon = () =>{
        const el = document.createElement('textarea');
        el.value = this.state.code;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.setState({snackStatus:true,snackMessage:'Code Copied!'});
    }

    handleClose = () =>{
        this.setState({snackStatus:false});
    }
    getCodeApi = () =>{
        let data = {user_id: this.state.isLogin,referral_code: this.state.codeInp};
        API({
            method: 'POST',
            url: "create_referral_code",
            data: data
        }).then(data => {

        if ('error' in data.data) {
          this.setState({error:"This referral code already exists, please try again with new code"});
        }else{
            this.setState({isCode:true,code:data.data.referral_code,codeform:false});
        }

        });   
    }
 render(){  
	return (
        <React.Fragment>
        <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.snackStatus}
        autoHideDuration={3000}
        onClose={this.handleClose}
        message={this.state.snackMessage}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={this.handleClose}>
              <i class="fa fa-cross" ></i>
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
              <i class="fa fa-cross"></i>
            </IconButton>
          </React.Fragment>
        }
      />
        <section class="invite" id="invite">
    <div class="o-children-0-2-63">
            <main role="main">
               <div class="imageContainer-0-2-2143"></div>
               <div class="mainContainer-0-2-2052">
                  <div class="contentContainer-0-2-2050">
                     <div class="mainContainer-0-2-2159">
                        {this.state.isLogin &&  this.state.isCode && <React.Fragment><div class="boxContainer-0-2-2145">
                           <div class="title-0-2-2148 h1">{strings.refer_friends_get_rewarded}</div>
                           <div class="contentContainer-0-2-2152">
                              <div class="shareBoxContainer-0-2-2161">
      <div class="containerTitle-0-2-2156 title4">{strings.share_your_referral_link}</div>
                                 <div class="mainContainer-0-2-2169">
                                    <div class="contentContainer-0-2-2166">
                                       <div class="copyBoxContainer-0-2-2168">
                                          <div class="copyBox-0-2-2167">
                                             <div>
                                                <div class="n-referralBoxContainer-0-2-493">
                                                   <div class="input-group mb-3">
                                                      <input type="data" value={this.state.code} disabled class="form-control" placeholder="" />
                                                      <div class="input-group-append">
                                                        <span class="input-group-text copy-code-btn pointer-arrow" onClick={(e)=>{this.copyCoupon()}}>{strings.copy_code_title}</span>
                                                      </div>
                                                    </div>
                                                </div>
                                                <div class="n-hintContainer-0-2-500">
                                                   <div class="n-hintText-0-2-501" style={{paddingLeft: '0px'}}></div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="shareButtonsContainer-0-2-2171">
                                          {this.state.message && <div class="sharethis-inline-share-buttons mt-5">
                                                                                    <InlineShareButtons
                                                                                      config={{
                                                                                        alignment: 'center',  // alignment of buttons (left, center, right)
                                                                                        color: 'social',      // set the color of buttons (social, white)
                                                                                        enabled: true,        // show/hide buttons (true, false)
                                                                                        font_size: 16,        // font size for the buttons
                                                                                        labels: 'cta',        // button labels (cta, counts, null)
                                                                                        language: 'en',       // which language to use (see LANGUAGES)
                                                                                        networks: [           // which networks to include (see SHARING NETWORKS)
                                                                                          'whatsapp',
                                                                                          'linkedin',
                                                                                          'messenger',
                                                                                          'facebook',
                                                                                          'twitter',
                                                                                          'google',
                                                                                          'pinterest',
                                                                                          'reddit',
                                                                                          'wechat',
                                                                                          'email'
                                                                                        ],
                                                                                        padding: 12,          // padding within buttons (INTEGER)
                                                                                        radius: 4,            // the corner radius on each button (INTEGER)
                                                                                        show_total: true,
                                                                                        size: 40,             // the size of each button (INTEGER)
                                                                             
                                                                                        // OPTIONAL PARAMETERS
                                                                                        url: 'http://helwa.io/ '+this.state.message, // (defaults to current url)
                                                                                        description: "Helwa - Never pay full price for online shopping again",       // (defaults to og:description or twitter:description)
                                                                                        title: 'Helwa',            // (defaults to og:title or twitter:title)
                                                                                        message: this.state.message,     // (only for email sharing)
                                                                                        subject: 'Have some Helwa',  // (only for email sharing)
                                                                                      }}
                                                                                    />
                                                                                </div>}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="separator-0-2-2160">
                                 <div class="greyBar-0-2-2158"></div>
                                 <span>or</span>
                                 <div class="greyBar-0-2-2158"></div>
                              </div>
                              <div class="emailInputContainer-0-2-2155">
                                                                                    <div class="containerTitle-0-2-2156 title4">{strings.share_via_email}</div>
                                 <div>
                                    <div>
                                       <input type="data" placeholder={strings.enter_email_address} onChange={this.handleChange} name="email" value={this.state.email} className="form-control mb-2" />
                                    </div>
                                 </div>
                                 <div class="button-0-2-2151">
                                    <div>
                                       <button aria-label="Send" onClick={(e)=>{this.sendInvitationPost()}} type="" class="btn-follow primary-16-0-2-229">
                                          <div class="btnContentContainer-0-2-29">
                                             <div class="btnCopy-0-2-37">{strings.send}</div>
                                          </div>
                                       </button>
                                    </div>
                                 </div>                              
                                 <div class="emailButtonContainer-0-2-2154">
                                    <div>
                                       <div class="socialSync-0-2-2192">
                                          <div class="socialSyncText-0-2-2199 title1"></div>
                                          <div class="socialSyncButtonContainer-0-2-2191">
                                             <div class="googleSyncButton-0-2-2201 title2">
                                                <picture><img alt="gmail icon" class="loading load gmailIcon" src="images/invite/gmail-icon.png" title="" /></picture>
                                                <div class="googleSyncCopy-0-2-2202">{strings.gmail}</div>
                                             </div>
                                             <button class="outlookSyncButton-0-2-2209 title2">
                                                <picture><img alt="outlook icon" class="loading load outlookIcon" src="images/invite/outlook-logo.png" title="" /></picture>
                                                <div class="outlookSyncCopy-0-2-2210">{strings.outlook}</div>
                                             </button>
                                             <div>
                                                <div class="container-0-2-2215">
                                                   <div class="iconContainer-0-2-2216">
                                                      <div class="h-icon icon-0-2-2224">
                                                         <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14 8A6 6 0 112 8a6 6 0 0112 0zm-12.98.02a7 7 0 1014 0 7 7 0 00-14 0zm8.547 3.052L9 11V7H6.437A.437.437 0 006 7.437c0 .25.186.46.433.491L7 8v3l-.567.072a.495.495 0 00-.433.491c0 .242.195.437.437.437h3.126a.437.437 0 00.437-.437c0-.25-.186-.46-.433-.491zM7 4h2v2H7V4z" fill="#9e9e9e"></path>
                                                         </svg>
                                                      </div>
                                                   </div>
                                                   <div class="tooltipContainer-0-2-2219 top">
                                                      <div class="tooltipArrow-0-2-2221 top"></div>
                                                      <div class="copyContent-0-2-2222">{strings.helwa_does_not_store_your_contacts_or_contact_them_without_your_permission}</div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                      </React.Fragment>}
                      {this.state.isLogin && !this.state.isCode  && <React.Fragment><div style={{height:'335px'}} className="boxContainer-0-2-2145">
                           <div class="title-0-2-2148 h1">{strings.refer_friends_get_rewarded}</div>
                           <div className="row">
                                            <div className = "col-md-5">
                                            <input
                                                className="form-control"
                                                placeholder="Enter Referral code of your choice"
                                                onChange={(e) => {this.changeInput(e)}}
                                                name="codeInp"
                                            />
                                            <span style={{color:'red'}}>{this.state.error}</span>
                                            </div>
                                            <div className="col-md-1">
                                            <button className="btn btn-primary" onClick={(e) => {this.getCodeApi()}}>Submit</button>
                                            </div>

                                        </div>
                        </div>
                      </React.Fragment>}
                     {!this.state.isLogin && <React.Fragment><div style={{height:'335px'}} class="boxContainer-0-2-2145">
                                                <div class="title-0-2-2148 h1">{strings.please_register_with_helwa_for_get_rewards}</div>
                                               <div className="registerbtn ml-auto">
                                                <Link to="/login">{strings.register}</Link>
                                               </div>
                                             </div>
                                          </React.Fragment>}
                     </div>
                     <div class="mainContainer-0-2-2229 userLoggedIn">
                        <div class="titleContainer-0-2-2235">
                           <div class="title-0-2-2237 h1">{strings.how_it_works}</div>
                        </div>
                        <div class="contentContainer-0-2-2225">
                           <div class="imagesContainer-0-2-2228">
                              <picture>
                                 <img alt="White gift box with blue ribbons" class="loading load image" src="images/invite/gift-box.png" />
                              </picture>
                              <div class="greyBar-0-2-2226"></div>
                              <picture>
                                 <img alt="Orange shopping bag" class="loading load image" src="images/invite/shopping-bag.png" />
                              </picture>
                              <div class="greyBar-0-2-2226"></div>
                              <picture>
                                 <img alt="Blue, white, and orange gift cards" class="loading load image" src="images/invite/gift-cards.png" />
                              </picture>
                           </div>
                           <div class="stepsContainer-0-2-2233">
                              <div class="step-0-2-2230">
                                 <div class="stepTitle-0-2-2231">{strings.invite}</div>
                                 <div class="stepBody-0-2-2232">{strings.invite_refer_text}</div>
                              </div>
                              <div class="step-0-2-2230">
                                 <div class="stepTitle-0-2-2231">{strings.collect_rewards}</div>
                                 <div class="stepBody-0-2-2232">{strings.collect_reward_text}</div>
                              </div>
                              <div class="step-0-2-2230">
                                 <div class="stepTitle-0-2-2231">{strings.get_gift_cards}</div>
                                 <div class="stepBody-0-2-2232">{strings.get_gift_card_text}</div>
                              </div>
                           </div>
                        </div>
                     </div>
                     
                     <div class="mainContainer-0-2-2249">
                        <div class="contentContainer-0-2-2248">
                           <div class="title-0-2-2251 h1">{strings.referral_activity}</div>
                           <div class="text-0-2-2250 body5">{strings.referral_activity_text}</div>
                           <div class="mainContainer-0-2-2336">
                              <div class="progressBoxContainerMobile-0-2-2339">
                                 <div class="boxContainer-0-2-2269">
                                    <div class="contentContainer-0-2-2271">
                                       <div class="title-0-2-2278 title2">{strings.your_referral_progress}</div>
                                       <div class="progressBar-0-2-2274">
                                          <div class="progressBarActive-0-2-2275 red" style={{width:'5%'}}></div>
                                       </div>
                                       <div class="completedText-0-2-2270 label2"><span>{strings.completed} </span><span class="number">1</span><span> OF </span><span class="number">200</span> REFERRALS</div>
                                    </div>
                                    <div class="separator-0-2-2277"></div>
                                    <div class="detailsContainer-0-2-2272">
                                       <div class="potentialGold-0-2-2273 title1"><span>{strings.potential} </span><span>{strings.five_helwa_points}</span></div>
                                       <div class="receivedGold-0-2-2276 title1"><span>{strings.received} </span><span>{strings.zero_helwa_points}</span></div>
                                    </div>
                                 </div>
                              </div>
                              <div class="boxContainer-0-2-2334 forceHeight">
                                 <div class="title-0-2-2346 title2">
                                    Your Referrals
                                    <div class="container-0-2-2348">
                                       <div class="anchorTextElement-0-2-2350">
                                          <div class="toolTipElement-0-2-2347 link2">{strings.what_is_helwa_points}</div>
                                       </div>
                                       <div class="tooltipContainer-0-2-2352 bottom">
                                          <div class="tooltipArrow-0-2-2354 bottom"></div>
                                          <div class="copyContent-0-2-2355" style={{width: '175px', maxWidth: '400px'}}>{strings.helwa_redemption_text}</div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="contentContainer-0-2-2335">
                                    <div class="referralCardsContainer-0-2-2341">
                                       <div class="referralCard-0-2-2340 red">
                                          <div class="referredEmail-0-2-2342 body3">hite****@tbreak.com</div>
                                          <div class="textContainer-0-2-2345">
                                             <div class="referralStatus-0-2-2343 title0 red">{strings.invitation_sent}</div>
                                             <div class="referralValue-0-2-2344 title1">{strings.potential_aed10_helwa_points}</div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="progressBoxContainer-0-2-2338">
                                 <div class="boxContainer-0-2-2269">
                                    <div class="contentContainer-0-2-2271">
                                       <div class="title-0-2-2278 title2">{strings.your_referral_progress}</div>
                                       <div class="progressBar-0-2-2274">
                                          <div class="progressBarActive-0-2-2275 red" style={{width: '5%'}}></div>
                                       </div>
                                       <div class="completedText-0-2-2270 label2"><span>{strings.completed} </span><span class="number">1</span><span> OF </span><span class="number">200</span> {strings.REFERRALS}</div>
                                    </div>
                                    <div class="separator-0-2-2277"></div>
                                    <div class="detailsContainer-0-2-2272">
                                       <div class="potentialGold-0-2-2273 title1"><span>{strings.potential} </span><span>{strings.five_helwa_points}</span></div>
                                       <div class="receivedGold-0-2-2276 title1"><span>{strings.received} </span><span>{strings.zero_helwa_points}</span></div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="toolTipContainer-0-2-2252">
                              <div class="container-0-2-2291">
                                 <div class="anchorTextElement-0-2-2293">
                                    <div class="toolTipElement-0-2-2253 link2">{strings.what_is_helwa_points}</div>
                                 </div>
                                 <div class="tooltipContainer-0-2-2295 bottom-left">
                                    <div class="tooltipArrow-0-2-2297 bottom-left"></div>
                                    <div class="copyContent-0-2-2298" style={{width: '175px', maxWidth: '400px'}}>{strings.helwa_redemption_text}</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  
                  </div>
                  
               </div>
            </main>
    </div>
</section>
		
        </React.Fragment>
	);
}
}

export default HowWorks;