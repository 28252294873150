import React from 'react';
import Header from '../component/header';
import Footer from '../component/footer';
import strings from '../lang/index';
class Privacy extends React.Component {
  render(){
  return (
    <React.Fragment>
    <Header />
    <section class="banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
 <h1>{strings.privacy_policy}</h1>
<br />
<br />
{strings.privacy_text1}<br /><br />

<strong>{strings.privacy_text2}</strong><br />
{strings.privacy_text3}<br />
<br />

<strong>{strings.privacy_text4}</strong><br />
{strings.privacy_text5}<br />
<br />

<strong>{strings.privacy_text6}</strong><br />
{strings.privacy_text7}<br />
{strings.privacy_text8}<br />
{strings.privacy_text9}<br />
{strings.privacy_text10}<br /><br />

{strings.privacy_text11}<br />
<br />

<strong>{strings.privacy_text12}</strong><br />
{strings.privacy_text13}<br />
<br />

<strong>{strings.privacy_text14}</strong><br />
{strings.privacy_text15}<br />
<br />

<strong>{strings.privacy_text_g_login}</strong><br />
The Platform uses Facebook and Google Login to offer a convenient method for users to log in. The following data is captured when you log in through Facebook or Google - Name, Email, Photo, Location. This data is used to offer you a more personalized service based on your location. Your email address is used to keep track of your Helwa Points.
<br/>
You may permanently delete your account at any time by logging into the Helwa mobile app (available on Google Play and Apple App Store) and choosing the delete account option in the profile section in the settings tab. By deleting your account, you will also delete all your data that has been collected.
<br/>
For more information on how to delete your account and your data permanently, you can also contact admin@helwa.io.<br />
<br />

<strong>{strings.privacy_text16}</strong><br />
{strings.privacy_text17}<br />
<br />

<strong>{strings.privacy_text18}</strong><br />
{strings.privacy_text19}<br />
<br />

<strong>{strings.privacy_text20}</strong><br />
{strings.privacy_text21}<br />
<br />

{strings.privacy_text22}<br />
<br />

<strong>{strings.privacy_text23}</strong><br />
{strings.privacy_text24}<br />
<br />

<strong>{strings.privacy_text25}</strong><br />
{strings.privacy_text26}<br />
<br />

<strong>{strings.privacy_text27}</strong><br />
{strings.privacy_text28}<br />
<br />

<strong>{strings.privacy_text29}</strong><br />
{strings.privacy_text30}

              
                
            </div>
    </div>
    </div>
</section>
<Footer />

    </React.Fragment>);
}
}

export default Privacy;