import React from 'react';
import Header from '../component/header';
import Footer from '../component/footer';
import Refer from '../component/Refer1';
class Home extends React.Component {
    componentDidMount() {
        
    }
 render(){   
    return (
    <React.Fragment>
        <Header page="refer" />
        <Refer />
        <Footer />
    </React.Fragment>);
    }
}

export default Home;