import React from 'react';
import { Link } from 'react-router-dom';
import strings from '../lang/index';
class News extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        
    }
 render(){  
	return (
        <React.Fragment>
        <section class="press-reviews">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 presscol">
                    <p class="mb-4">"Helwa users can save between 5% to 20% when shopping online"</p>
                    <hr class="mb-4"></hr>
                    <figure><a href="https://www.arabianbusiness.com/retail/448886-retail-discount-app-launches-in-uae" target="_blank"><img src="/images/arabianbusiness.jpg" alt="" /></a></figure>
                </div>
                
                <div class="col-lg-4 presscol">
                    <p class="mb-4">"UAE residents turn to coupon app to save money"</p>
                    <hr class="mb-4"></hr>
                    <figure><a href="https://www.khaleejtimes.com/technology/uae-residents-turn-to-coupon-app-to-save-money" target="_blank"><img src="/images/khaleejtimes.png" alt="" /></a></figure>
                </div>
                
                <div class="col-lg-4 presscol">
                    <p class="mb-4">"Region’s first automatic coupon finder site helps buyers never overpay again"</p>
                    <hr class="mb-4"></hr> 
                    <figure><a href="https://www.techradar.com/news/helwa-aims-to-become-uber-of-middle-east-for-saving-money" target="_blank"><img src="/images/techradar.png" alt="" /></a></figure>
                </div>
            </div>
        </div>
    </section>

		
    </React.Fragment>
	);
}
}

export default News;