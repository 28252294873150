import React from 'react';
import { Link,Redirect } from 'react-router-dom';
import SocialButton from '../component/SocialButton';
import { GoogleLogin } from 'react-google-login';
import Header from '../component/header';
import Footer from '../component/footer';
import API from "../api.js";
import strings from '../lang/index';
import 'react-notifications/lib/notifications.css';
import SimpleReactValidator from 'simple-react-validator';
class Login extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          login:false,
          tab:2,
          token:"",
          password:"",
          cpassword:"",
          loading:false,
          successMsg:"",
          errorMsg:""

      }
      this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
      let login = false;
      let tab = 1; 
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      let urlParams = new URLSearchParams(window.location.search);
      let myParam = urlParams.get('token');
      this.setState({token:myParam});
      
  }
  



  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };



resetPassword = ()=>{
  if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return ;
  }
  this.setState({loading:true})
  let data = {'token': this.state.token,'password':this.state.password};
  API({
    method: 'POST',
    url: "set_new_password",
    data: data
  }).then(data => {
    console.log(data);
    if ('error' in data.data) {
      this.setState({error:data.data.error,loading:false});
    }else{
      this.setState({successMsg:"Password changed successfully!",password:'',cpassword:'',loading:false});
      //this.setState({login:true}); 
    }
  }).catch(error => {
      this.setState({error:"Something went wrong",loading:false,errorMsg:"Something went wrong."});
      
  });
}



 render(){
  if (this.state.login) {
     return <Redirect to='/login'/>;
  }
  return (
    <React.Fragment>
    <Header />
      <section className="banner">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12">
      <div className="success-msg-login">{this.state.successMsg}</div>
      <div className="error-msg-login">{this.state.errorMsg}</div>
        <div className="col-md-12 loginsection loginsection2">
        <div className="row">
          <div class="wrapper fadeInDown">
            
            <div id="formContent">
              <div class="fadeIn first">
                <img className="login-logo" src="/images/helwa_logo.svg" />
              </div>  
              {this.state.tab === 2 && <h2 className="active"> Reset Password </h2>}
              <div className="row">
              <div className="col-md-12"><span className="srv-validation-message">{this.state.error}</span></div>
              {this.state.tab ===2 && <React.Fragment>
                
                <div className="col-md-12">
                  <input 
                    type="password"
                    class="fadeIn third"
                    name="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={(e) => { this.changeInput(e)} } />
                    {this.validator.message('Password', this.state.password, 'required')}
                </div>
                <div className="col-md-12">
                  <input 
                    type="password"
                    class="fadeIn third"
                    name="cpassword"
                    placeholder="Confirm Password"
                    value={this.state.cpassword}
                    onChange={(e) => { this.changeInput(e)} } />
                    {this.validator.message('Confirm password', this.state.cpassword, `required|in:${this.state.password}`, {messages: {in: 'This field must same as password'}})}
                </div>
                <div className="col-md-12">  
                  <input type="submit" class="fadeIn fourth" onClick={(e)=>this.resetPassword()} value={(this.state.loading)?"loading...":"Reset Password"} />
                  
                </div>
                </React.Fragment>}
                </div>
            
              <div id="formFooter">
                <Link class="underlineHover" to="/login-email" >Back</Link>
              </div>

            </div>
          </div>
        </div>
      </div>
        
        
      </div>
  </div>
  </div>
</section>
<Footer />
    </React.Fragment>);
}
}
export default Login;