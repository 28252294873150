import React from 'react';
import strings from '../lang/index';
import { Link } from 'react-router-dom';
class BannerGuest extends React.Component {
 constructor(props) {
        super(props);
        this.state = {
            isChrome:false,
            isMob:false
        }
    }
    componentDidMount() {
        let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        let isMob = (navigator.userAgent.match([/Android/i,/webOS/i,/iPhone/i,/iPad/i,/iPod/i,/BlackBerry/i,/Windows Phone/i]) === null)?false:true;
        
        this.setState({isChrome:isChrome,isMob:isMob});
    }
 render(){  
	return (
		<section class="banner">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 col-md-6 bannerleft my-auto">
                <h1 class="mb-3">{strings.installed_text1}</h1>
				<p class="desktop"><strong style={{color: '#f7c600'}}>{strings.what_next_text}</strong><br />
					{strings.installed_para1}
				<div class="buttonsection mt-5 d-flex">
					
					<div class="btnleft"><Link to="/stores">{strings.start_shopping}</Link></div>
				</div>
				<p class="mobdesc">{strings.use_helwa_coupons_txt}</p>
				
				
				<div class="buttonmob">
					<a href="https://apps.apple.com/us/app/id1519569679"><img src="/images/appstoreicon.jpg" alt="" /></a>
					<a href="https://play.google.com/store/apps/details?id=com.app.helwa"><img src="/images/googleplay.jpg" alt="" /></a>
				</div>
                </p>
			</div>
			<div class="col-lg-6 col-md-6 banner-right"><div style={{width:'100%', height:'100%', border: '1px solid #333', textAlign: 'center', padding: '10px'}}><h3 style={{color: '#f7c600'}}>Important Note: Permissions</h3>
			
			<img src="images/permits.png" alt="" />
			<br /><br />
 			{strings.permission_helper_text}
			</div></div>
	</div>
    </div>
</section>
	);
}
}


export default BannerGuest;