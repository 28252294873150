import React from 'react';
import Header from '../component/header';
import Footer from '../component/footer';
import ShopList from '../component/ShopList';
import LazyLoad from 'react-lazyload';
class Home extends React.Component {
    componentDidMount() {

        
    }
 render(){   
    return (
    <React.Fragment>
    <Header page="store" />
    <LazyLoad once={true} >
    <ShopList cat = {this.props.match.params.store} />
    </LazyLoad>
    <Footer />
    </React.Fragment>);
    }
}

export default Home;