import React from 'react';
import { Link,Redirect } from 'react-router-dom';
import SocialButton from '../component/SocialButton';
import { GoogleLogin } from 'react-google-login';
import Header from '../component/header';
import Footer from '../component/footer';
import API from "../api.js";
import strings from '../lang/index';
class Login extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          login:false,
          email:"",
          forceEmail:false
      }
  }
  componentDidMount() {
      if (localStorage.getItem('name')) {
          this.setState({login:true});
      }
  }
  handleSocialLogin = (googleUser) => {
    API({
      method: 'GET',
      url: "get_user?a_t="+googleUser.getAuthResponse().access_token+"&platform=gg",
    }).then(data => {
      localStorage.setItem('email', data.data.email);
      localStorage.setItem('name', data.data.name);
      localStorage.setItem('coins', data.data.total_coins);
      localStorage.setItem('user_id', data.data.user_id);
      localStorage.setItem('token', data.data.token);
      localStorage.setItem('fb_id', 1);
      localStorage.removeItem('loggedOut');
      localStorage.setItem('pic', data.data.profile_pic);
      this.setState({login:true});
    });
    
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };

 handleSocialLoginFb = (data) => {
  let ApiUrl = "";
  if (!this.state.forceEmail) {
    ApiUrl = "get_user?a_t="+data._token.accessToken+"&platform=fb";
  }else{
    ApiUrl = "get_user?a_t="+data._token.accessToken+"&platform=fb&email="+this.state.email;
  }
  API({
    method: 'GET',
    url: ApiUrl,
  }).then(data => {
    if ("error" in data.data) {
      this.setState({forceEmail:true})
    }else{
      localStorage.setItem('email', data.data.email);
      localStorage.setItem('name', data.data.name);
      localStorage.setItem('coins', data.data.total_coins);
      localStorage.setItem('user_id', data.data.user_id);
      localStorage.setItem('fb_id', 1);
      localStorage.removeItem('loggedOut');
      localStorage.setItem('token', data.data.token);
      localStorage.setItem('pic', data.data.profile_pic);
      this.setState({login:true});
    }
        
    

  });
}
 handleSocialLoginFailure = () => {

}
 render(){
  if (this.state.login) {
     return <Redirect to='/'/>;
  }
  return (
    <React.Fragment>
    <Header />
      <section className="banner">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12">

        <div className="col-md-12 loginsection">
        <div className="row">
          {!this.state.forceEmail && <div className="offset-xl-4 offset-lg-3 offset-md-3 col-xl-4 col-lg-6 col-md-6 loginarea"><br /><br />
                      <h1>{strings.create_account_text}</h1>
                      <p>{strings.login_text1} <b style={{color: '#f7c600'}}>{strings.login_text1}</b></p>
                      <div class="socialcontainer">
                                    <div class="socialbtn">
                                    <GoogleLogin
                                              clientId="183663094654-itt1p9468pcht6jcc1bfm3p83ncrui82.apps.googleusercontent.com"
                                              render={renderProps => (
                                                <a onClick={renderProps.onClick} disabled={renderProps.disabled} > <span><img src="/images/social-google.svg" alt=""/> {strings.google_login}</span>
                                                </a>
                                              )}
                                              buttonText="Login"
                                              onSuccess={this.handleSocialLogin}
                                              onFailure={this.handleSocialLogin}
                                              cookiePolicy={'single_host_origin'}
                                          /></div>
                                    <div class="socialbtn">
                                      <SocialButton
                                            provider='facebook'
                                            appId='233583124400708'
                                            onLoginSuccess={this.handleSocialLoginFb}
                                            onLoginFailure={this.handleSocialLoginFailure}
                                          >
                                
                                            <span> <img src="/images/social-fb-blue.svg" alt=""/> {strings.fb_login}</span>
                                          </SocialButton>
                                    </div>
          
          
          
                                    
                                  </div>
                      
                      {true && <div class="line-mid">
                                          <b>OR USE YOUR EMAIL ADDRESS</b>
                                        </div>}
                      {true && <div class="socialcontainer">
                                                <div class="socialbtn">
                                                  <Link to="/login-email?signup=1" class="email-btn" onClick="" disabled="" > <span> Sign Up</span>
                                                              </Link>
                                                  <Link to="/login-email" class="email-btn email-btn2" onClick="" disabled="" > <span>Sign In</span>
                                                            </Link>
                                                </div>
                                  </div>}
                      
                    </div>}
                    {this.state.forceEmail && <div className="offset-xl-4 offset-lg-3 offset-md-3 col-xl-4 col-lg-6 col-md-6 loginarea"><br /><br />
                      
                      <p>Please enter your email and login again with your facebook account</p>
                      <div class="socialcontainer">
                                   <input
                                    name="email"
                                    value={this.state.email}
                                    onChange={(e) => { this.changeInput(e)} }
                                    className="form-control mb-5"
                                    placeholder="Enter Email" 
                                  /> 
                                    <div class="socialbtn">
                                      <SocialButton
                                            provider='facebook'
                                            appId='233583124400708'
                                            onLoginSuccess={this.handleSocialLoginFb}
                                            onLoginFailure={this.handleSocialLoginFailure}
                                          >
                                
                                            <span> <img src="/images/social-fb-blue.svg" alt=""/> {strings.fb_login}</span>
                                          </SocialButton>
                                    </div>
          
          
          
                                    
                                  </div>
                      
                      
                    </div>}
        </div>
      </div>
        
        
      </div>
  </div>
  </div>
</section>
<Footer />
    </React.Fragment>);
}
}
export default Login;