import React from 'react';
import { Link,Redirect } from 'react-router-dom';
import SocialButton from '../component/SocialButton';
import { GoogleLogin } from 'react-google-login';
import Header from '../component/header';
import Footer from '../component/footer';
import API from "../api.js";
import strings from '../lang/index';
import 'react-notifications/lib/notifications.css';
import SimpleReactValidator from 'simple-react-validator';
class Login extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          login:false,
          tab:1,
          email:"",
          cEmail:"",
          password:"",
          name:"",
          country:"",
          cpassword:"",
          error:'',
          referral:"",
          successMsg:"",
          loading:false,
          linkFlag:false,
          linkFlagProcess:false

      }
      this.validator = new SimpleReactValidator();
  }
  componentDidMount() {
      let login = false;
      let tab = 1; 
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      let urlParams = new URLSearchParams(window.location.search);
      let myParam = urlParams.get('signup');
      let referral_code = urlParams.get('referral');
      if (parseInt(myParam) === 1) {
        tab = 2;
      }
      let referral ="";
      if (referral_code) {
        referral = referral_code;
      }
      if (localStorage.getItem('name')) {
          login = true;
      }
      this.setState({login:login,tab:tab,referral:referral});
  }


  changeTab = (tab) =>{
    this.setState({tab:parseInt(tab),email:'',password:''});
    this.validator.hideMessages();
    this.forceUpdate();
    this.setState({email:'',password:'',cpassword:'',error:'',country:'',name:'',referral:"",successMsg:''})
    this.validator = new SimpleReactValidator();
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  };

postSignup = ()=>{
  if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return ;
  }
  this.setState({loading:true,successMsg:'',error:''});
  let data = {email: this.state.email, password: this.state.password, name: this.state.name,country: this.state.country,from_arg:0,referral:this.state.referral};
  API({
    method: 'POST',
    url: "user_register",
    data: data
  }).then(data => {
    
    if ('error' in data.data) {
      this.setState({error:data.data.error,loading:false});
    }else{
      localStorage.setItem('email', data.data.email);
      localStorage.setItem('name', data.data.name);
      localStorage.setItem('token', data.data.token);
      localStorage.setItem('coins', 0);
      localStorage.setItem('user_id', data.data.user_id);
      localStorage.removeItem('loggedOut');
      localStorage.setItem('pic', '');
      this.setState({login:true,loading:false}); 
    }
    
  });
}
sendCofirmationLink = ()=>{
  this.setState({linkFlagProcess:true,linkFlag:false});
  let data = {email: this.state.cEmail};
  API({
    method: 'POST',
    url: "resend_confimation_email",
    data: data
  }).then(data => {
    
    if ('error' in data.data) {
      
      this.setState({error:data.data.error,linkFlagProcess:false});
    }else{
      this.setState({linkFlag:false,linkFlagProcess:false,successMsg: 'Verification email has been sent to you.'});
      window.scrollTo(0, 0);
    }
    
  });
}
login = (e)=>{
  if (!this.validator.allValid()) {
    this.validator.showMessages();
    this.forceUpdate();
    return ;
  }
  this.setState({loading:true,successMsg:'',error:''});
  let data = {'email': this.state.email, 'password': this.state.password};
  API({
    method: 'POST',
    url: "user_login",
    data: data
  }).then(data => {
    if ('error' in data.data) {
      let linkFlag = false;
      if ('resend_link' in data.data && data.data.resend_link) {
        linkFlag = true;
      }
      this.setState({error:data.data.error,loading:false,linkFlag:linkFlag,cEmail:this.state.email});
    }else{
      localStorage.setItem('email', data.data.email);
      localStorage.setItem('name', data.data.name);
      localStorage.setItem('token', data.data.token);
      localStorage.setItem('coins', 0);
      localStorage.setItem('user_id', data.data.user_id);
      localStorage.removeItem('loggedOut');
      localStorage.setItem('pic', '');
      this.setState({login:true,loading:false}); 
    }
  }).catch(error => {
      this.setState({error:"Something went wrong",loading:false});
  });
}


 render(){
  if (this.state.login) {
     return <Redirect to='/'/>;
  }
  return (
    <React.Fragment>
    <Header />
      <section className="banner">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12">
        <div className="success-msg-login">{this.state.successMsg}</div>
      </div>
      <div className="col-lg-12 col-md-12">

        <div className="col-md-12 loginsection loginsection2">
        <div className="row">
          <div class="wrapper fadeInDown">
            
            <div id="formContent">
              <div class="fadeIn first">
                <img className="login-logo" src="/images/helwa_logo.svg" />
              </div>  
              <h2 class={(this.state.tab === 1)?"active":""} onClick={(e)=>{this.changeTab(1)}}> Sign In </h2>
              <h2 class={(this.state.tab === 2)?"active":""} onClick={(e)=>{this.changeTab(2)}}>Sign Up </h2>

             
              

              <div className="row">
              <div className="col-md-12"><span className="srv-validation-message">{this.state.error}</span></div>
              {this.state.tab ===1 && <React.Fragment>
                <div className="col-md-12">
                  <input 
                    type="text" 
                    value={this.state.email}
                    onChange={(e) => { this.changeInput(e)} }
                    class="fadeIn second" 
                    name="email"
                    placeholder="Email" />
                    {this.validator.message('Email', this.state.email, 'required|email')}
                </div>
                <div className="col-md-12">
                  <input 
                    type="password"
                    class="fadeIn third"
                    name="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={(e) => { this.changeInput(e)} } />
                    {this.validator.message('Password', this.state.password, 'required')}
                    {!this.state.linkFlagProcess && this.state.linkFlag && <p className="confirm-error">Please verify your email first. If you haven't received your verification email, please <span className="confirm-error-link" onClick={(e)=>{this.sendCofirmationLink(e);}}>CLICK HERE</span></p> }
                    {this.state.linkFlagProcess && <p className="confirm-error">Wait...</p> }
                </div>

                <div className="col-md-12">
                 <input type="submit" onClick={(e)=>this.login(e)} class="fadeIn fourth" value={(this.state.loading)?"loading...":"Sign In"} />
                </div>
                </React.Fragment>}
              {this.state.tab ===2 && <React.Fragment>
                <div className="col-md-12">
                  <input
                    type="text"
                    value={this.state.name}
                    onChange={(e) => { this.changeInput(e)} }
                    class="fadeIn second"
                    name="name"
                    placeholder="Full Name" />
                    {this.validator.message('Name', this.state.name, 'required')}
                </div>
                <div className="col-md-12">  
                  <input
                    type="text"
                    class="fadeIn second"
                    name="email"
                    value={this.state.email}
                    onChange={(e) => { this.changeInput(e)} }
                    placeholder="Email" />
                    {this.validator.message('Email', this.state.email, 'required|email')}
                </div>
                <div className="col-md-12">
                  <select
                    class="fadeIn second"
                    name="country"
                    value={this.state.country}
                    onChange={(e) => { this.changeInput(e)} } >
                    <option value="">Select Country of Residence</option>
                    <option value="United Arab Emirates">United Arab Emirates</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Oman">Oman</option>
                    <option value="Egypt">Egypt</option>
                  </select>
                  {this.validator.message('Country', this.state.country, 'required')}
                </div>
                <div className="col-md-12">
                  <input 
                    type="text"
                    class="fadeIn third"
                    name="referral"
                    placeholder="Referral code(optional)"
                    value={this.state.referral}
                    onChange={(e) => { this.changeInput(e)} } />
                  
                </div>
                <div className="col-md-12">
                  <input 
                    type="password"
                    class="fadeIn third"
                    name="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={(e) => { this.changeInput(e)} } />
                    {this.validator.message('Password', this.state.password, 'required')}
                </div>
                <div className="col-md-12">
                  <input 
                    type="password"
                    class="fadeIn third"
                    name="cpassword"
                    placeholder="Confirm Password"
                    value={this.state.cpassword}
                    onChange={(e) => { this.changeInput(e)} } />
                    {this.validator.message('Confirm password', this.state.cpassword, `required|in:${this.state.password}`, {messages: {in: 'It must matched with password.'}})}
                </div>
                <div className="col-md-12">  
                  <input type="submit" class="fadeIn fourth" onClick={(e)=>this.postSignup()} value={(this.state.loading)?"loading...":"Sign Up"} />
                </div>
                </React.Fragment>}
                </div>
            
              <div id="formFooter">
                <Link class="underlineHover" to="/forgot">Forgot Password?</Link>
              </div>

            </div>
          </div>
        </div>
      </div>
        
        
      </div>
  </div>
  </div>
</section>
<Footer />
    </React.Fragment>);
}
}
export default Login;