import React from 'react';
import Header from '../component/header';
import Footer from '../component/footer';
import HowWorks from '../component/HowWorksSteps';
import News from '../component/News';
import BannerUser from '../component/BannerUser';
import LazyLoad from 'react-lazyload';

class Home extends React.Component {
    componentDidMount() {
        
    }
 render(){   
    return (
    <React.Fragment>
    <Header />
    <LazyLoad once={true} >
    <BannerUser />
    </LazyLoad>
    <LazyLoad once={true} >
    <News />
    <HowWorks />
    </LazyLoad>
    <Footer />
    </React.Fragment>);
    }
}

export default Home;