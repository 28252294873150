import LocalizedStrings from 'react-localization';
import SimpleReactValidator from 'simple-react-validator';
SimpleReactValidator.addLocale('ar', {
    required: 'هذه الخانة مطلوبه.'
});

SimpleReactValidator.addLocale('en', {
    required: 'This field is required.'
});

let strings = new LocalizedStrings({
    en: {
        how_does_works: "How does it work?",
        online_store_list: "Online Stores List",
        faq: "FAQ",
        register: "Register",
        hi: "Hi",
        company_text: "Company",
        get_helwa: "Get Helwa",
        never_pay_full_price: "Get better prices for your online shopping in the UAE",
        helwa_find_coupon_text: "Helwa is your personal shopping assistant, finding you discount coupons for your favourite online stores, and automatically inserting them during checkout, saving you money on your purchase and offering cashback points.",
        use_helwa_coupons_txt: "Use Helwa coupons to get the best discounts on your online shopping. Helwa works on stores in the United Arab Emirates, Saudi Arabia, Bahrain, Qatar, Kuwait, Oman and Egypt.",
        no_google1: "Hi! We noticed you're not using Google Chrome.",
        no_google2: " Made by the awesome folks at Google, Chrome offers what we think is the best browsing experience on the web. In order to use Helwa, you must first download Google Chrome. And yes, like Helwa, Chrome is also free to download!",
        get_chrome: "Click here to get Google Chrome",
        get_helwa_free: "Get Helwa - it's free!",
        helwa_installed: "Helwa is installed!",
        helwa_popup_info: "Shop online on your favourite online stores and Helwa will pop up any time it finds coupon codes for you.",
        right_reserved: "Helwa.io. All Rights Reserved.",
        privacy_policy: "Privacy Policy",
        terms_conditions: "Terms and Conditions",
        contact_us: "Contact us",
        helwa_footer1: "Helwa does not have, nor does it purport to have, ownership of third-party trademarks. Third-party trademarks that are visible, or accessible directly or indirectly, via the Helwa platform, do not necessarily have a relationship with Helwa or third-parties associated with Helwa or service providers of Helwa.",
        helwa_footer2: "We use cookies to improve our site and your shopping experience. By continuing to browse our site you accept our cookie policy.",
        made_with_love_in_uae: "Made with love in the UAE",
        install_for_free: "Install Helwa for Free",
        helwa_free_clicks: "Helwa is free to download and all it takes is two clicks.",
        shop_on_fav_site: "Shop on your favorite sites",
        how_works_text1: "Here's how it works",
        how_works_text2: "Shop as usual on your favourite online stores. When you get to the checkout page, Helwa will automatically find the best coupons for you.",
        how_works_text3: "Helwa will automatically apply the coupon with the greatest savings for you. You save cash!",
        how_works_text4: "Find coupons for your favourite store",
        how_works_text5: "You can either search for or scroll through our list to find coupons for your store.",
        how_works_text6: "Once you find a coupon code you're looking for, click the button to copy the code.",
        how_works_text7: "Apply Coupon Code to Save money",
        how_works_text8: "When you get to the coupon code section of your favourite shopping website or shopping app, enter the code to get a discount. You've now saved money!",
        save_money: "Save money",
        copy_code: "Copy Coupon Code",
        faq_title: "Frequently Asked Questions",
        faq1_title: "Is Helwa really free?",
        faq2_title: "How can I tell if Helwa is installed?",
        faq3_title: "Does Helwa collect my data?",
        faq4_title: "I don't want to save money. How do I uninstall Helwa?",
        faq5_title: "Does Helwa collect my data?",
        faq6_title: "Helwa doesn't have coupons for my favourite store. Can you find me coupons?",
        faq1_body: "There are no hidden costs. We save you the pain of having to search coupons on the Internet and give you the best price on your purchase. In the end, we save you time and money!",
        faq2_body: "After you have installed Helwa with two clicks, you will see the Helwa icon at the top of your browser bar. This means Helwa is installed.",
        faq3_body: "You do not need an account to use Helwa and you do not have to disclose any personal data to use coupons. If you choose to login through Facebook or Google, we will collect your name and email address in order to record Helwa cashback points you have earned.",
        faq4_body: 'On your browser, click on More Tools > Extensions. You will see Helwa listed here and can click the "Remove" button to uninstall Helwa.',
        faq5_body: "We will collect your name and email address in order to create a profile for you on our system.",
        faq6_body1: "Please send us an email at ",
        faq6_body2: "and we'll do our best to find coupons for your favourite store.",
        shoplist_txt1: "Online Stores - Updated Daily",
        shoplist_txt2: "Here's a list of the online stores Helwa has coupons for. We add coupons for new stores every day. If your favourite online store is missing, please drop us an email at ",
        shoplist_txt3: "and we'll do our best to add it in.",
        cat: "Categories",
        all: "ALL",
        loading: "Loading...",
        online_store: "Online Stores",
        previous: "Previous",
        next: "Next",
        create_account_text: "Create an account",
        login_text1: "Now, let’s get you started by creating an account.",
        login_text2: "You need an account to earn Helwa points.",
        google_login: "Login with Google",
        fb_login: "Login with Facebook",
        installed_text1: "Congratulations! Helwa is now installed!",
        installed_text2: "Please wait while we re-direct you to the login page.",
        privacy_text1: 'Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use the Helwa.io website and extension (the "Platform"). By accessing or using the Platform, you agree to this privacy policy',
        privacy_text2: 'Changes to the Privacy Policy',
        privacy_text3: 'We reserve the right to change this policy at any time, so please re-visit this page frequently. We respect children\’s privacy. We do not knowingly or intentionally collect personal information from children under age 13. By using the Platform, you warrant that you are either 18 years of age or using the Site with the supervision of a parent or guardian. If you are under the age of 13, please do not submit any personal information to us, and rely on a parent or guardian to assist you.',
        privacy_text4: 'INFORMATION YOU PROVIDE',
        privacy_text5: 'The Platform may request that you provide certain personally identifiable information, which is information that may reasonably be used to identify you as a specific individual (“Personal Information”). Examples of Personal Information include your name, e-mail address, location, username and profile picture. The Platform may also request additional information about you, such as, demographic information (e.g., your gender, ZIP code, or marital status), or information about your preferences and interests. The Platforms may require that you provide certain information to use the Platforms or certain features, and the required information may change from time to time depending on, among other things, the nature of your relationship with us. If we combine demographic or similar information with the Personal Information we collect, we will treat the combined information as Personal Information under this Privacy Policy.',
        privacy_text6: 'INFORMATION WE COLLECT AUTOMATICALLY',
        privacy_text7: 'We may also automatically collect information about you when you access or use the Site or transact business with Us, including:',
        privacy_text8: '•   Transaction Information:  When you purchase a product, we collect information about the transaction purchase price, and the date of transaction.',
        privacy_text9: '•   Log Information: We obtain information about your use of our Platform, including the type of browser you use, access times, pages viewed, your IP address and the page you visited before navigating to this Site.',
        privacy_text10: '•   Location Information: We may collect information about the precise location of your device when you consent to the collection of this information. We may also collect information about your approximate location each time you access this Site.Information Collected by Cookies and other Tracking Technologies: We may use cookies, web beacons, and other tracking technologies to collect information about you and your interaction with this Platform, including information about your browsing behavior, purchase behavior, and other engagement with the Services. We use this information in one or more of the ways described in the "Use of Information" section below. Most web browsers are set to accept cookies by default, but you can usually change your browser settings to remove or reject cookies.',
        privacy_text11: 'The methods used to collect Usage Information may include:',
        privacy_text12: 'COOKIES',
        privacy_text13: 'A cookie is a data file placed on a Device when it is used to visit our Platform or otherwise access our online content. Cookies may be used for many purposes, such as remembering you and your preferences and tracking your visits to the Platform. Immediately below is information about one such vendor we use across one or more Platform.',
        privacy_text14: 'GOOGLE ANALYTICS',
        privacy_text15: 'The Platform uses a specific cookie in order to facilitate the use of Google Universal Analytics for users logged-in to the Platforms (“Logged-In User”). If you are a Logged-In User, The Platform may use your Helwa user ID in combination with Google Universal Analytics and Google Analytics to track and analyze the pages of the services you visit. We do this only to better understand how you use the Platform, with a view to offering improvements for all Helwa users; and to tailor our business and marketing activities accordingly, both generally and specifically to you. Google Analytics cookies do not provide Helwa with any Personal Information and you may opt-out of this feature by installing the Google Analytics Opt-out Browser Add-on. Note: This opt-out feature only works on your desktop browser and not on a mobile device.',
        privacy_text16: 'WEB BEACONS AND PIXELS',
        privacy_text17: 'Small graphic images or other web programming code called “web beacons” “clear GIFs” or “1×1 pixels” may be used on the Platform or in our emails and may be invisible to you. Web beacons may be connected to your Personal Information, if you have provided it. Web beacons may be used for various purposes, such as counting visitors to the Platforms, monitoring how users navigate the Platform, counting how many emails that were sent were actually opened, or counting how many times a link was accessed. We do not sell, rent, trade, or otherwise distribute information about you gathered through the web beacons or 1×1 pixels with any third parties, except for third parties who provide us with advertising, marketing, research or analysis services.',
        privacy_text18: 'HOW WE USE THE INFORMATION WE COLLECT',
        privacy_text19: 'We may use information about you, including Personal Information and Usage Information, to: (1) allow you to use the Platform or to provide related customer service; (2) provide or offer you information, products, or services, any of which may be tailored to you; (3) send or display special offers or advertisements from Helwa (as hereinafter defined) and our business partners; (4) process your registration, including verifying that information you provide is active and valid; (5) process a transaction you initiate; (6) improve the Platform and our products, and for internal business purposes, including the measurement of the Platform’s effectiveness; (7) contact you with regard to your use of the Platform and, in our discretion, changes to our policies; (8) identify and protect against fraudulent activities, transactions, and abuse of the Platform, and to protect the rights and property of Helwa, its partners, and customers; and (9) as otherwise described in this Privacy Notice, for purposes disclosed at the time you provide your information, or with your consent.',
        privacy_text20: 'DATA SECURITY',
        privacy_text21: 'We take reasonable measures, including administrative, technical, and physical safeguards, to protect information about you from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction.',
        privacy_text22: 'Of course, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while we strive to protect your personal information, we cannot guarantee its absolute security. IN NO EVENT WILL THE VIDEOGRAM COMPANY, HELWA OR ITS AFFILIATES , OR THEIR MANAGERS, MEMBERS, OR EMPLOYEES BE LIABLE IN ANY MANNER WHATSOEVER FOR ANY INCIDENTAL, CONSEQUENTIAL, INDIRECT, SPECIAL, OR PUNITIVE DAMAGES ARISING OUT OF YOUR ACCESS, USE OR INABILITY TO USE THE PLATFORM, OR IN CONNECTION WITH ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS OR LINE OR SYSTEM FAILURE (INCLUDING LOSS PROFITS, LOSS OF BUSINESS OR DATA, BUSINESS INTERRUPTION, AND DAMAGES THAT RESULT FROM INACCURACY OF THE INFORMATION OR INCONVENIENCE, DELAY, OR LOSS OF THE USE OF THE PLATFORM). THE FOREGOING LIMITATIONS APPLY WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER BASIS, EVEN IF HELWA OR ITS AFFILIATES OR THEIR MANAGERS, MEMBERS, OR EMPLOYEES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.',
        privacy_text23: 'INTEGRITY OF YOUR DATA',
        privacy_text24: 'Helwa processes personal information only for the purposes for which it was collected and in accordance with this Policy. We review our data collection, storage and processing practices to ensure that we only collect, store and process the personal information needed to provide or improve our services. We take reasonable steps to ensure that the personal information we process is accurate, complete, and current, but we depend on our users to update or correct their personal information whenever necessary.',
        privacy_text25: 'THIRD PARTY SITES',
        privacy_text26: 'While using the Platform you may encounter links to third party websites. Helwa is not responsible for these sites and has no responsibility or liability whatsoever with regard to privacy matters or any other legal matter with respect to such sites. We encourage you to carefully read the privacy policies and the terms of use or service of such websites. Our terms of use and our privacy policy apply only to information collected by us in accordance with this policy.',
        privacy_text27: 'OPT OUT/CORRECTIONS',
        privacy_text28: 'Upon your request, We will (a) correct or update your personal information; (b) stop sending emails to your email address; and/or (c) disable your account to prevent any future purchases through that account. You can make these requests by emailing Us at admin@helwa.io',
        privacy_text29: 'UPDATES TO THIS POLICY',
        privacy_text30: 'If We change or update this Privacy Policy, We will post changes and updates on the Site so that you will always be aware of what information We collect, use and disclose. We encourage you to review this Policy from time to time so you will know if the Privacy Policy has been changed or updated. If you have any questions about the Policy, please contact us at admin@helwa.io.',
        term_text1: 'Welcome to Helwa. This website is operated by The Videogram Company and this Terms and conditions govern your access to and use of the Helwa.io website and Chrome Browser Extension, including any functionality and services offered on or through Helwa.io. Thus, throughout the website, Helwa is referred to as (“Website” or “Company,” or “we” or “us” or “our”) and users are referred to as (“you” or “user”).',
        term_text2: 'Please, read this terms and condition thoroughly before getting to any part of the site and before clicking to acknowledge or consent to any choice made accessible to you on the grounds that any use implies that you have acknowledged and consented to be bound by the Terms and conditions on this site. Along these lines, if you don\'t consent to this Terms and condition, you should not access or use the Website in any capacity.',
        term_text3: 'By accepting to use Helwa, you have acknowledged and consented to be bound by the Terms and conditions on this site as it governs your relationship with Helwa. In addition, we reserve the right to update, replace or change any part of these Terms and conditions by posting updates and/or changes to our website. It is your responsibility to check this page periodically to take note of any changes we may have to the Terms of Service. Following the posting of any changes and your continued access to the website or the use of the services establishes acceptance of those changes.',
        term_text4: 'PERSONAL ACCOUNT',
        term_text5: 'If you purchase any Products or Services on the Helwa Platform you will be required to register and set up a personal account (“Personal Account”). You are responsible for the accuracy of the information included in your Personal Account. Your Personal Account will be protected in accordance with our Privacy Policy. You are responsible for: (1) keeping your Personal Account up to date and updating information as necessary; and (2) maintaining the confidentiality of your Personal Account including all registration information and any passwords assigned to you. You agree to accept responsibility for all activity that occurs on your Personal Account. You may not: (1) use an account of another person with the intent to impersonate that person; or (2) use an account to which another person has rights without such person’s authorization. Failure to comply with the foregoing shall constitute a breach of this Agreement, which may result in immediate termination of your Personal Account.',
        term_text6: 'You are responsible for all usage or activity on your Personal Account, including the use of the account by any third-party authorized by you to use your password. Any fraudulent, abusive, or otherwise illegal activity may be grounds for termination of your Personal Account in our sole discretion. Helwa reserves the right to disclose any personal information included in your Personal Account or related to your use of the Helwa Platform, Products and Services (as hereinafter defined), to third-parties in the course of investigating abuse of our Products and Services and to forward such information to appropriate law enforcement agencies. You must notify Helwa of any known or suspected unauthorized use of your Personal Account, or any known or suspected breach of security, including loss, theft, or unauthorized disclosure of your password or credit card information.',
        term_text7: 'If you are under the age of 18, you may only purchase products or Services with the permission of a parent or guardian. Helwa reserves the right to refuse service, terminate accounts or cancel orders in its sole discretion. The Gram reserves the right to terminate your Personal Account for violation of any of these rules.',
        term_text8: 'USER REPRESENTATIONS',
        term_text9: 'By using the Site, you represent and warrant that:',
        term_text10: '(1) all registration information you submit will be true, accurate, current, and complete;',
        term_text11: '(2) you will maintain the accuracy of such information and promptly update such registration information as necessary; ',
        term_text12: '(3) you have the legal capacity and you agree to comply with these Terms of Use;',
        term_text13: '[(4) you are not under the age of 13];',
        term_text14: '(5) you are not a minor in the jurisdiction in which you reside [, or if a minor, you have received parental permission to use the Site]; ',
        term_text15: '(6) you will not access the Site through automated or non-human means, whether through a bot, script or otherwise; ',
        term_text16: '(7) you will not use the Site for any illegal or unauthorized purpose;',
        term_text17: '(8) your use of the Site will not violate any applicable law or regulation.',
        term_text18: 'If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).',
        term_text19: 'RIGHTS',
        term_text20: 'Helwa does not claim ownership of any Content that you post on or through the Service. Instead, you hereby grant to Helwa a perpetual, non-exclusive, fully paid and royalty-free, transferable, sub-licensable, worldwide license to use the Content that you post on or through the Service.  Any information you transmit to Helwa that is not payment or financial information, whether by direct entry, submission, electronic mail or otherwise, including data, questions, comments, or suggestions, will be treated as non-confidential and non-proprietary and will become the property of Helwa. Such information may be used for any purpose, including but not limited to reproduction, solicitations, disclosure, transmission, publication, broadcast, and posting. Helwa shall be free to use any ideas, concepts, know-how, or techniques contained in any communication you send to Helwa by any other means for any purpose whatsoever, including, but not limited to, developing and marketing products using such information.',
        term_text21: 'INTELLECTUAL PROPERTY RIGHTS',
        term_text22: 'Helwa is the owner and the lawful licensee of all the of rights to the Web Site and its content ("Web Site Content"). Web Site means the materials displayed on this website, including without limitation all editorial materials, information, photographs, illustrations, artwork and other graphic materials, and names, logos and trademarks, are solely our property and may be protected, at our sole discretion, by copyright, trade mark and other intellectual property laws. Helwa respects the intellectual property rights of others and we expect our users to honor the same. All title, ownership, and intellectual property rights in the Web Site and the Web Site Content shall remain in Helwa, its affiliates or licensor’s of the Web Site content, as the case may be. We\'re committed to protect the intellectual property rights of third parties and to provide our members with a safe platform to buy or order.',
        term_text23: 'All rights, not generally asserted under this Agreement by Helwa, are hereby reserved. Any data or advertisements contained on, disseminated through, or connected, downloaded or got from any of the services contained on the Web Site or any offer showed on or regarding any service offered on the Web Site ("Website Information") is proposed, exclusively to give general information to the personal use of the User(s), who completely acknowledge any responsibility and liabilities emerging from and out of the use of such Information.',
        term_text24: 'Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the Content and the Marks.',
        term_text25: 'OWNERSHIP',
        term_text26: 'All content provided or displayed by Helwa through the Platform, including (without limitation) all designs, infrastructure, graphics, pictures, illustrations, software, artwork, video, music, sound, names, words, titles, phrases, logos and marks (the “Platform Content”), are owned or licensed by Helwa and are protected by copyright, trade-mark and other intellectual property laws.',
        term_text27: 'Helwa expressly reserves all rights in the Platform, the Platform Services, and the Platform Content that are not specifically granted to you. You acknowledge that all right, title and interest in the Platform, the Platform Services, the Platform Content, and any update, adaptation, translation, customization or derivative work thereof, and all intellectual property rights therein will remain with Helwa (or third party suppliers, if applicable), and that the Platform, Platform Services, and Platform Content are licensed and not “sold” to you.',
        term_text28: 'THIRD PARTY WEBSITES',
        term_text29: 'Helwa may contract with third-party service providers for certain services, such as payment services, and may also provide links to other third-party websites that are not under the control of Helwa.  In addition to these Terms of Use, you may be bound by any additional terms required by the third-party service providers. Helwa makes no representations about any other website to which you may have access through the Platform, and accepts no liability for activities or content on third-party sites. Helwa is not responsible for the accuracy or reliability of any information, data, opinions, advice, or statements made on third-party sites or for the quality of any products or services available on such sites.',
        term_text30: 'TERMINATION',
        term_text31: 'You may terminate the Terms at any time by closing your account, discontinuing your use of this Site and providing Us with a notice of termination. We reserve the right, without notice and in our sole discretion, to terminate your right to use this Site, or any portion of this Site, and to block or prevent your future access to and use of this Site or any portion of this Site.',
        term_text32: 'DISCLAIMERS',
        term_text33: 'Except as expressly provided, this Site, including all Site Content, and services provided on or in connection with this Site is provided on an "AS IS" and "WITH ALL FAULTS" basis without representations, warranties or conditions of any kind, either express or implied. WE DISCLAIM ALL OTHER REPRESENTATIONS, WARRANTIES, CONDITIONS, AND DUTIES, EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES, DUTIES OR CONDITIONS: (A) OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR USE, RESULTS, TITLE, AND NON-INFRINGEMENT; AND (B) CREATED BY TRADE USAGE, COURSE OF DEALING OR COURSE OF PERFORMANCE. We do not represent or warrant that this Site is accurate, complete, reliable, current or error-free. We do not represent or warrant that this Site or our servers are free of viruses or other harmful components.',
        term_text34: 'COPYRIGHT NOTICE',
        term_text35: 'Helwa logos are trademarks of The Videogram Company, All rights reserved. All other trademarks appearing on Helwa are the property of their respective owners.',
        term_text36: 'TRADEMARKS',
        term_text37: 'The names of actual companies and products mentioned herein may be the trademarks of their respective owners. The example companies, organizations, products, domain names, email addresses, logos, people and events depicted herein are fictitious. No association with any real company, organization, product, domain name, email address, logo, person, or event is intended or should be inferred.',
        term_text38: 'Any rights not expressly granted herein are reserved.',
        term_text39: 'CONTACT US',
        term_text40: 'If you have any questions, comments, or complaints about our terms and conditions, please contact us at: admin@helwa.io',
        report_issue_title: 'Report an issue/error',
        reort_issue_desc: "Your feedback helps us get better. Please take a few minutes of your time to give us valuable feedback to help improve Helwa.",
        name_text: "Name",
        email_text: "Email",
        how_use_helwa: "How do you use Helwa?",
        how_use_extension: "Chrome Extension",
        how_use_ios: "Apple iOS app",
        how_use_android: "Android app",
        issue_title_text: "Please describe the issue",
        submit_text: "Submit",
        wait_text: "Wait...",
        thanks_report_text: "Thank you for letting us know know about this. Your feedback helps us get better.",
        feedback_title: "Feedback",
        feedback_desc: "Your feedback helps us get better. Please take a few minutes of your time to give us valuable feedback to help improve Helwa.",
        feedback_type: "I'd like to",
        feedback_type_coupon: "Request a coupon for an online store",
        feedback_type_work: "Work with you as an online store",
        feedback_type_complaint: "Submit a complaint",
        feedback_type_feedback: "General Feedback",
        thanks_feedback_text: "Thanks, for providing your valuable feedback. Your feedback helps us get better",
        contact_us: "Contact Us",
        contact_desc: "Thanks for stopping by and checking out Helwa. We're love to hear from you - you can contact us through the following or fill in the form below",
        list_store_helwa: "List my store on Helwa",
        thanks_success_text: "Thank you for contacting us – we will get back to you soon!",
        your_message_text: "Your message",
        share_title: "Share Helwa with Friends",
        share_text1: "The more people we have using Helwa, the bigger the discounts we can get for you from online retailers. Please help us by spreading the word about Helwa to your friends and family.",
        share_text2: "Please click any of the options below to share Helwa.",
        coupon_copied_msg: "Coupon Copied!",
        copy_code_title: "Copy Code",
        back_to_store_text: "Back to Online Stores List",
        coupon_code_txt: "Coupon Codes",
        refer_title: "Refer Us",
        no_coupon_text: "No coupon found.",
        featured_text: "Featured",
        go_to_store_txt: "Go to store",
        email_login: "Register with Email",
        refer_friends_get_rewarded: "Refer friends, get rewarded.",
        share_your_referral_link: "Share your referral link",
        share_via_email: "Share via email",
        enter_email_address: "Enter email address",
        send: "Send",
        outlook: "Outlook",
        gmail: "Gmail",
        helwa_does_not_store_your_contacts_or_contact_them_without_your_permission: "Helwa does not store your contacts or contact them without your permission.",
        how_it_works: "How it works",
        invite: "Invite",
        invite_refer_text: "Refer friends with your unique link and pass along Helwa Points.",
        collect_rewards: "Collect rewards",
        collect_reward_text: "You'll get AED10 Helwa Points when they make their first purchase.",
        get_gift_cards: "Get Gift Cards",
        get_gift_card_text: "Redeem for a gift card once you collect AED100 Helwa Points.",
        referral_activity: "Referral Activity",
        referral_activity_text: "See who you’ve referred and how many Helwa Points you’ve earned.",
        your_referral_progress: "Your Referral Progress",
        completed: "COMPLETED",
        REFERRALS: "REFERRALS",
        potential: "Potential",
        five_helwa_points: "+500 Helwa Points",
        received: "Received",
        zero_helwa_points: "+0 Helwa Points",
        helwa_redemption_text: "Helwa Points is our way of giving back to our members. Earn Helwa Points on all kinds of purchases — Helwa Points you can redeem for gift cards.",
        what_is_helwa_points: "What is Helwa Points?",
        invitation_sent: "Invitation sent",
        potential_aed10_helwa_points: "Potential AED10 Helwa Points",
        please_register_with_helwa_for_get_rewards: "Please register with helwa for get rewards",
        report_coupon: "Report Coupon",
        yes: "Yes",
        no: "No",
        ok: "OK",
        are_you_sure_you_want_to_report_this_coupon_not_working: "Are you sure you want to report this coupon not working.",
        coupon_code: "Coupon Code",
        please_wait: "Please Wait...",
        copy_code_and_visit_site: "Copy code and visit site",
        on_minimum_purchase_of_aed: "On minimum purchase of AED",
        coupon_terms: "Coupon Terms",
        how_do_coupon_works: "How do coupon works?",
        coupon_works_desc: "Simply copy and paste this coupon into the coupon/ voucher section during checkout to get your discount",
        report_cpupon_title: "Report this coupon not working",
        expires_on: "Expires on",
        official_site: "Official Site",
        congratulation_redeemed_text: "Congratulations! Your Helwa Points have been Redeemed!",
        congratulation_redeemed_text2: "Your gift card will be emailed to you within 24 hours.",
        less_points_err1: "Sorry, you do not have enough points to redeem this item",
        less_points_err2: "Please collect more points to redeem this reward.",
        save_money_earn_free_gift_cards_repeat: "Save Money. Earn free gift cards. Repeat.",
        helwa_points_is_our_rewards_program_where_you_can_earn_gift_cards: "Helwa Points is our rewards program where you can earn gift cards.",
        how_helwa_points_works: "How Helwa Points Works",
        free_and_secure: "It's free and completely secure",
        activate_helwa_points: "Activate Helwa Points",
        earn_helwa_points_when_you_shop: "Earn Helwa Points when you shop",
        redeem_for_gift_cards: "Redeem for Gift Cards",
        get_gift_cards_from_popular_stores: "Get gift cards from popular stores",
        earn_helwa_points_and_get_a_gift_card: "Earn Helwa Points and get a gift card",
        earn_helwa_crausal_desc: "Once you earn Helwa Points, you can redeem them for gift cards from your favourite stores.",
        browse_gift_vouchers: "Browse Gift Vouchers",
        have_more_questions: "Have more questions?",
        learn_more: "Learn More",
        OVERVIEW: "OVERVIEW",
        REDEEM: "REDEEM",
        referral_bonus: "Referral Bonus",
        transaction_history: "Transaction History",
        DATE: "DATE",
        TRANSACTION: "TRANSACTION",
        HELWA_POINTS: "HELWA POINTS",
        no_data_found: "No data found",
        redeem: "Redeem",
        referral_history: "Referral History",
        pending_brac: "(Pending)",
        points: "Points",
        official_site: "Official Site",
        coupons_a_deals: "Coupons & Deals",
        get_8_cashback_with_helwa: "Get upto 8% cashback with Helwa",
        earn_helwa_text: "Earn Helwa Points with your purchase to redeem gift cards to your favorite stores",
        upto_8_cashback: "upto 8% cashback",
        coupons: "Coupons",
        on_mininum_spend_of: "On mininum spend of",
        get_coupon: "Get Coupon",
        activate_deal: "Activate Deal",
        expires_on: "Expires on",
        activate_offer: "Activate Offer",
        close: "Close",
        deals: "Deals",
        what_next_text: "What do I do next?",
        installed_para1: "Shop online on your favourite online stores and Helwa will pop up any time it finds coupon codes or Helwa Rewards Points for you.",
        permission_helper_text: "As we add more shopping sites to our list, you will receive a prompt to allow additional permissions. Helwa requires these permissions to read the coupon code field on the site and insert coupons for you.",
        start_shopping: "Start Shopping",
        helwa_cb_term_heading: "Helwa Points and Cashback Terms",
        cb_term_text1: "1. Cashback from online stores are awarded as Helwa Points",
        cb_term_text2: "2. Once a transaction is made, your Helwa Points are added to your account as Pending.",
        cb_term_text3: "3. The Cashback needs to be approved by the stores for your points to be Approved. Timings for approval vary from store to store and is beyond our control. It may take up to 3 months for your points to be approved, depending on the store.",
        cb_term_text4: "4. The Online store may reject your points in case of a return, fraudulent transaction or incorrect attribution.",
        cb_term_text5: "5. The percentage of Cashback indicated is the maximum available for that particular store. This amount may vary depending on the category of the item purchased.",
        cb_term_text6: "6. If you receive no Cashback points from a store offering cashback, this means that the item you have purchased is excempt from Cashback points.",
        Logout: "Logout",
        phone: "phone",
        privacy_text_g_login: "FACEBOOK AND GOOGLE LOGIN DATA USAGE",
        privacy_textlogin_g_desc: "The Platform uses Facebook and Google Login to offer a convenient method for users to log in. The following data is captured when you log in through Facebook or Google - Name, Email, Photo, Location. This data is used to offer you a more personalized service based on your location. Your email address is used to keep track of your Helwa Points. You may permanently delete your account at any time by logging into the Helwa mobile app (available on Google Play and Apple App Store) and choosing the delete account option in the profile section in the settings tab. For more information on how to delete your account permanently, you can also contact admin@helwa.io."
    },
    ar: {
        company_text: "شركة",
        how_does_works: "كيف يعمل؟",
        online_store_list: "قائمة المتاجر على الانترنت",
        faq: "الأسئلة الشائعة",
        register: "التسجيل",
        hi: "مرحباً",
        get_helwa: "احصل على حلوة",
        never_pay_full_price: "لا تدفع الثمن الكامل عند التسوق عبر الإنترنت مرة أخرى!",
        helwa_find_coupon_text: "يعثر على كوبونات الخصم لمتجرك المفضل عبر الانترنت، ويدرجها تلقائياً عند صفحة السداد، مما يوفر المال على مشترياتك ويقدم نقاط استرداد نقدي.",
        use_helwa_coupons_txt: "كوبونات حلوة للحصول على أفضل التخفيضات عند التسوق عبر الانترنت. يعمل حلوة على متاجر في الإمارات العربية المتحدة والمملكة العربية السعودية والبحرين وقطر والكويت وعُمان ومصر.",
        no_google1: "مرحباً! لاحظنا أنك لا تستعمل متصفح جوجل كروم.",
        no_google2: "يأتي متصفح كروم من قبل الفريق الرائع في جوجل، وهو يقدم برأينا أفضل تجربة تصفح على الويب. ولكي تتمكن من استخدام حلوة، عليك تحميل مصفح جوجل كروم. ونعم، إنه مثل حلوة، مجاني التحميل!",
        get_chrome: "اضغط هنا للحصول على جوجل كروم",
        get_helwa_free: "احصل على حلوة - إنه مجاني!",
        helwa_installed: "تم تثبيت حلوة!",
        helwa_popup_info: "تسوق عبر متاجرك المفضلة على الانترنت وسيظهر تطبيق حلوة في كل مرة يعثر فيها على رموز كوبونات لك.",
        right_reserved: "Helwa.io.  جميع الحقوق محفوظة.",
        privacy_policy: "سياسة الخصوصية",
        terms_conditions: "الشروط والأحكام",
        helwa_footer1: "لا يمتلك حلوة أو يدعي امتلاك أية علامات تجارية لأطراف ثالثة. إن العلامات التجارية للأطراف الثالثة التي يمكن رؤيتها أو الوصول إليها بشكل مباشر أو غير مباشر عن طريق منصة حلوة، ليس لها بالضرورة علاقة مع حلوة أو أطراف ثالثة مرتبطة بحلوة أو مزودي خدمة حلوة.",
        helwa_footer2: "نستخدم ملفات تعريف الارتباط لتحسين موقعنا وتجربة التسوق الخاصة بك. إن استمرارك في تصفح موقعنا يعني أنك تقبل سياسة ملفات تعريف الارتباط الخاصة بنا.",
        made_with_love_in_uae: "صُنع بكل حب في الإمارات العربية المتحدة",
        install_for_free: "قم بتثبيت حلوة مجاناً",
        helwa_free_clicks: "حلوة مجاني التحميل وكل ما يتطلبه هو نقرتين.",
        shop_on_fav_site: "تسوق عبر مواقعك المفضلة.",
        how_works_text1: "وإليك كيف يعمل",
        how_works_text2: "تسوق كالمعتاد عبر متاجرك المفضلة على الانترنت. عندما تصل إلى صفحة السداد، سيعثر تطبيق حلوة تلقائياً على أفضل الكوبونات لك.",
        how_works_text3: "سيقوم حلوة بتطبيق الكوبون تلقائياً مع أكبر توفير لك. ستوفر المال!",
        how_works_text4: "اعثر على كوبونات لمتجرك المفضل",
        how_works_text5: "يمكنك إما البحث عبر قائمتنا أو تصفحها للعثور على كوبونات للمتجر الذي تريد.",
        how_works_text6: "بمجرد عثورك على رمز القسيمة الذي تبحث عنه، انقر الزر لنسخ الرمز.",
        how_works_text7: "تطبيق رمز الكوبون لتوفير المال",
        how_works_text8: "عندما تصل إلى قسم رمز الكوبون في متجر التسوق أو تطبيق التسوق المفضل لديك، أدخل الرمز للحصول على الخصم. لقد وفرت المال الآن!",
        save_money: "وفر المال",
        copy_code: "انسخ رمز الكوبون",
        faq_title: "الأسئلة الشائعة",
        faq1_title: "هل حلوة مجاني حقاً؟",
        faq2_title: "كيف يمكنني معرفة ما إذا تم تثبيت حلوة؟",
        faq3_title: "هل يجمع حلوة بياناتي؟",
        faq4_title: "لا أريد توفير المال. كيف ألغي تثبيت حلوة؟",
        faq5_title: "هل يجمع حلوة بياناتي؟",
        faq6_title: "لا يحتوي حلوة على كوبونات لمتجري المفضل. هل يمكنك العثور على كوبونات لأجلي؟",
        faq1_body: "لا توجد تكاليف خفية. نحن نوفر عليك عناء البحث عن كوبونات على الانترنت ونعطيك أفضل سعر ممكن على مشترياتك. في نهاية المطاف، نحن نوفر عليك الوقت والمال!",
        faq2_body: "بعدما قمت بتثبيت حلوة بمجرد نقرتين، سترى أيقونة حلوة ظاهرة في أعلى شريط المتصفح. هذا يعني أنه تم تثبيت حلوة.",
        faq3_body: "لست بحاجة إلى حساب لاستخدام حلوة وليس عليك الكشف عن أية بيانات شخصية لاستخدام الكوبونات. إذا اخترت تسجيل الدخول عبر فيسبوك أو جوجل، فسنأخذ اسمك وبريدك الإلكتروني لتسجيل نقاط استرداد النقود التي حصلت عليها من حلوة.",
        faq4_body: 'على متصفحك، انقر على: المزيد من الأدوات > الإضافات. سترى حلوة على القائمة هناك، انقر على زر "إزالة" لإلغاء تثبيت حلوة.',
        faq5_body: "سنأخذ اسمك وبريدك الإلكتروني لإنشاء ملف شخصي لك على نظامنا.",
        faq6_body1: "يرجى مراسلتنا على ",
        faq6_body2: " وسنبذل قصارى جهدنا للعثور على كوبونات لمتجرك المفضل.",
        shoplist_txt1: "المتاجر على الإنترنت - محدثة بشكل يومي",
        shoplist_txt2: "فيما يلي قائمة بالمتاجر على الإنترنت التي يمتلك حلوة كوبونات لها. نحن نضيف كوبونات لمتاجر جديدة كل يوم. إذا لم يكن متجرك المفضل على الإنترنت بينها، يرجى مراسلتنا عبر البريد الإلكتروني ",
        shoplist_txt3: "وسنبذل قصارى جهدنا لإضافته.",
        cat: "الفئات",
        all: "الكل",
        loading: "جاري التحميل...",
        online_store: "المتاجر",
        previous: "السابق",
        next: "التالي",
        create_account_text: "إنشاء حساب",
        login_text1: "الآن، فلنبدأ عبر إنشاء حساب لك.",
        login_text2: "تحتاج إلى حساب لكسب نقاط حلوة.",
        google_login: "تسجيل الدخول عبر جوجل",
        fb_login: "تسجيل الدخول عبر فيسبوك",
        installed_text1: "تهانينا! تم تثبيت حلوة!",
        installed_text2: "يرجى الانتظار ريثما نعيد توجيهك إلى صفحة تسجيل الدخول.",
        privacy_text1: 'Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use the Helwa.io website and extension (the "Platform"). By accessing or using the Platform, you agree to this privacy policy',
        privacy_text2: 'Changes to the Privacy Policy',
        privacy_text3: 'We reserve the right to change this policy at any time, so please re-visit this page frequently. We respect children\’s privacy. We do not knowingly or intentionally collect personal information from children under age 13. By using the Platform, you warrant that you are either 18 years of age or using the Site with the supervision of a parent or guardian. If you are under the age of 13, please do not submit any personal information to us, and rely on a parent or guardian to assist you.',
        privacy_text4: 'INFORMATION YOU PROVIDE',
        privacy_text5: 'The Platform may request that you provide certain personally identifiable information, which is information that may reasonably be used to identify you as a specific individual (“Personal Information”). Examples of Personal Information include your name, e-mail address, location, username and profile picture. The Platform may also request additional information about you, such as, demographic information (e.g., your gender, ZIP code, or marital status), or information about your preferences and interests. The Platforms may require that you provide certain information to use the Platforms or certain features, and the required information may change from time to time depending on, among other things, the nature of your relationship with us. If we combine demographic or similar information with the Personal Information we collect, we will treat the combined information as Personal Information under this Privacy Policy.',
        privacy_text6: 'INFORMATION WE COLLECT AUTOMATICALLY',
        privacy_text7: 'We may also automatically collect information about you when you access or use the Site or transact business with Us, including:',
        privacy_text8: '•   Transaction Information:  When you purchase a product, we collect information about the transaction purchase price, and the date of transaction.',
        privacy_text9: '•   Log Information: We obtain information about your use of our Platform, including the type of browser you use, access times, pages viewed, your IP address and the page you visited before navigating to this Site.',
        privacy_text10: '•   Location Information: We may collect information about the precise location of your device when you consent to the collection of this information. We may also collect information about your approximate location each time you access this Site.Information Collected by Cookies and other Tracking Technologies: We may use cookies, web beacons, and other tracking technologies to collect information about you and your interaction with this Platform, including information about your browsing behavior, purchase behavior, and other engagement with the Services. We use this information in one or more of the ways described in the "Use of Information" section below. Most web browsers are set to accept cookies by default, but you can usually change your browser settings to remove or reject cookies.',
        privacy_text11: 'The methods used to collect Usage Information may include:',
        privacy_text12: 'COOKIES',
        privacy_text13: 'A cookie is a data file placed on a Device when it is used to visit our Platform or otherwise access our online content. Cookies may be used for many purposes, such as remembering you and your preferences and tracking your visits to the Platform. Immediately below is information about one such vendor we use across one or more Platform.',
        privacy_text14: 'GOOGLE ANALYTICS',
        privacy_text15: 'The Platform uses a specific cookie in order to facilitate the use of Google Universal Analytics for users logged-in to the Platforms (“Logged-In User”). If you are a Logged-In User, The Platform may use your Helwa user ID in combination with Google Universal Analytics and Google Analytics to track and analyze the pages of the services you visit. We do this only to better understand how you use the Platform, with a view to offering improvements for all Helwa users; and to tailor our business and marketing activities accordingly, both generally and specifically to you. Google Analytics cookies do not provide Helwa with any Personal Information and you may opt-out of this feature by installing the Google Analytics Opt-out Browser Add-on. Note: This opt-out feature only works on your desktop browser and not on a mobile device.',
        privacy_text16: 'WEB BEACONS AND PIXELS',
        privacy_text17: 'Small graphic images or other web programming code called “web beacons” “clear GIFs” or “1×1 pixels” may be used on the Platform or in our emails and may be invisible to you. Web beacons may be connected to your Personal Information, if you have provided it. Web beacons may be used for various purposes, such as counting visitors to the Platforms, monitoring how users navigate the Platform, counting how many emails that were sent were actually opened, or counting how many times a link was accessed. We do not sell, rent, trade, or otherwise distribute information about you gathered through the web beacons or 1×1 pixels with any third parties, except for third parties who provide us with advertising, marketing, research or analysis services.',
        privacy_text18: 'HOW WE USE THE INFORMATION WE COLLECT',
        privacy_text19: 'We may use information about you, including Personal Information and Usage Information, to: (1) allow you to use the Platform or to provide related customer service; (2) provide or offer you information, products, or services, any of which may be tailored to you; (3) send or display special offers or advertisements from Helwa (as hereinafter defined) and our business partners; (4) process your registration, including verifying that information you provide is active and valid; (5) process a transaction you initiate; (6) improve the Platform and our products, and for internal business purposes, including the measurement of the Platform’s effectiveness; (7) contact you with regard to your use of the Platform and, in our discretion, changes to our policies; (8) identify and protect against fraudulent activities, transactions, and abuse of the Platform, and to protect the rights and property of Helwa, its partners, and customers; and (9) as otherwise described in this Privacy Notice, for purposes disclosed at the time you provide your information, or with your consent.',
        privacy_text20: 'DATA SECURITY',
        privacy_text21: 'We take reasonable measures, including administrative, technical, and physical safeguards, to protect information about you from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction.',
        privacy_text22: 'Of course, no method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while we strive to protect your personal information, we cannot guarantee its absolute security. IN NO EVENT WILL THE VIDEOGRAM COMPANY, HELWA OR ITS AFFILIATES , OR THEIR MANAGERS, MEMBERS, OR EMPLOYEES BE LIABLE IN ANY MANNER WHATSOEVER FOR ANY INCIDENTAL, CONSEQUENTIAL, INDIRECT, SPECIAL, OR PUNITIVE DAMAGES ARISING OUT OF YOUR ACCESS, USE OR INABILITY TO USE THE PLATFORM, OR IN CONNECTION WITH ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS OR LINE OR SYSTEM FAILURE (INCLUDING LOSS PROFITS, LOSS OF BUSINESS OR DATA, BUSINESS INTERRUPTION, AND DAMAGES THAT RESULT FROM INACCURACY OF THE INFORMATION OR INCONVENIENCE, DELAY, OR LOSS OF THE USE OF THE PLATFORM). THE FOREGOING LIMITATIONS APPLY WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR ANY OTHER BASIS, EVEN IF HELWA OR ITS AFFILIATES OR THEIR MANAGERS, MEMBERS, OR EMPLOYEES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.',
        privacy_text23: 'INTEGRITY OF YOUR DATA',
        privacy_text24: 'Helwa processes personal information only for the purposes for which it was collected and in accordance with this Policy. We review our data collection, storage and processing practices to ensure that we only collect, store and process the personal information needed to provide or improve our services. We take reasonable steps to ensure that the personal information we process is accurate, complete, and current, but we depend on our users to update or correct their personal information whenever necessary.',
        privacy_text25: 'THIRD PARTY SITES',
        privacy_text26: 'While using the Platform you may encounter links to third party websites. Helwa is not responsible for these sites and has no responsibility or liability whatsoever with regard to privacy matters or any other legal matter with respect to such sites. We encourage you to carefully read the privacy policies and the terms of use or service of such websites. Our terms of use and our privacy policy apply only to information collected by us in accordance with this policy.',
        privacy_text27: 'OPT OUT/CORRECTIONS',
        privacy_text28: 'Upon your request, We will (a) correct or update your personal information; (b) stop sending emails to your email address; and/or (c) disable your account to prevent any future purchases through that account. You can make these requests by emailing Us at admin@helwa.io',
        privacy_text29: 'UPDATES TO THIS POLICY',
        privacy_text30: 'If We change or update this Privacy Policy, We will post changes and updates on the Site so that you will always be aware of what information We collect, use and disclose. We encourage you to review this Policy from time to time so you will know if the Privacy Policy has been changed or updated. If you have any questions about the Policy, please contact us at admin@helwa.io.',
        term_text1: 'Welcome to Helwa. This website is operated by The Videogram Company and this Terms and conditions govern your access to and use of the Helwa.io website and Chrome Browser Extension, including any functionality and services offered on or through Helwa.io. Thus, throughout the website, Helwa is referred to as (“Website” or “Company,” or “we” or “us” or “our”) and users are referred to as (“you” or “user”).',
        term_text2: 'Please, read this terms and condition thoroughly before getting to any part of the site and before clicking to acknowledge or consent to any choice made accessible to you on the grounds that any use implies that you have acknowledged and consented to be bound by the Terms and conditions on this site. Along these lines, if you don\'t consent to this Terms and condition, you should not access or use the Website in any capacity.',
        term_text3: 'By accepting to use Helwa, you have acknowledged and consented to be bound by the Terms and conditions on this site as it governs your relationship with Helwa. In addition, we reserve the right to update, replace or change any part of these Terms and conditions by posting updates and/or changes to our website. It is your responsibility to check this page periodically to take note of any changes we may have to the Terms of Service. Following the posting of any changes and your continued access to the website or the use of the services establishes acceptance of those changes.',
        term_text4: 'PERSONAL ACCOUNT',
        term_text5: 'If you purchase any Products or Services on the Helwa Platform you will be required to register and set up a personal account (“Personal Account”). You are responsible for the accuracy of the information included in your Personal Account. Your Personal Account will be protected in accordance with our Privacy Policy. You are responsible for: (1) keeping your Personal Account up to date and updating information as necessary; and (2) maintaining the confidentiality of your Personal Account including all registration information and any passwords assigned to you. You agree to accept responsibility for all activity that occurs on your Personal Account. You may not: (1) use an account of another person with the intent to impersonate that person; or (2) use an account to which another person has rights without such person’s authorization. Failure to comply with the foregoing shall constitute a breach of this Agreement, which may result in immediate termination of your Personal Account.',
        term_text6: 'You are responsible for all usage or activity on your Personal Account, including the use of the account by any third-party authorized by you to use your password. Any fraudulent, abusive, or otherwise illegal activity may be grounds for termination of your Personal Account in our sole discretion. Helwa reserves the right to disclose any personal information included in your Personal Account or related to your use of the Helwa Platform, Products and Services (as hereinafter defined), to third-parties in the course of investigating abuse of our Products and Services and to forward such information to appropriate law enforcement agencies. You must notify Helwa of any known or suspected unauthorized use of your Personal Account, or any known or suspected breach of security, including loss, theft, or unauthorized disclosure of your password or credit card information.',
        term_text7: 'If you are under the age of 18, you may only purchase products or Services with the permission of a parent or guardian. Helwa reserves the right to refuse service, terminate accounts or cancel orders in its sole discretion. The Gram reserves the right to terminate your Personal Account for violation of any of these rules.',
        term_text8: 'USER REPRESENTATIONS',
        term_text9: 'By using the Site, you represent and warrant that:',
        term_text10: '(1) all registration information you submit will be true, accurate, current, and complete;',
        term_text11: '(2) you will maintain the accuracy of such information and promptly update such registration information as necessary; ',
        term_text12: '(3) you have the legal capacity and you agree to comply with these Terms of Use;',
        term_text13: '[(4) you are not under the age of 13];',
        term_text14: '(5) you are not a minor in the jurisdiction in which you reside [, or if a minor, you have received parental permission to use the Site]; ',
        term_text15: '(6) you will not access the Site through automated or non-human means, whether through a bot, script or otherwise; ',
        term_text16: '(7) you will not use the Site for any illegal or unauthorized purpose;',
        term_text17: '(8) your use of the Site will not violate any applicable law or regulation.',
        term_text18: 'If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).',
        term_text19: 'RIGHTS',
        term_text20: 'Helwa does not claim ownership of any Content that you post on or through the Service. Instead, you hereby grant to Helwa a perpetual, non-exclusive, fully paid and royalty-free, transferable, sub-licensable, worldwide license to use the Content that you post on or through the Service.  Any information you transmit to Helwa that is not payment or financial information, whether by direct entry, submission, electronic mail or otherwise, including data, questions, comments, or suggestions, will be treated as non-confidential and non-proprietary and will become the property of Helwa. Such information may be used for any purpose, including but not limited to reproduction, solicitations, disclosure, transmission, publication, broadcast, and posting. Helwa shall be free to use any ideas, concepts, know-how, or techniques contained in any communication you send to Helwa by any other means for any purpose whatsoever, including, but not limited to, developing and marketing products using such information.',
        term_text21: 'INTELLECTUAL PROPERTY RIGHTS',
        term_text22: 'Helwa is the owner and the lawful licensee of all the of rights to the Web Site and its content ("Web Site Content"). Web Site means the materials displayed on this website, including without limitation all editorial materials, information, photographs, illustrations, artwork and other graphic materials, and names, logos and trademarks, are solely our property and may be protected, at our sole discretion, by copyright, trade mark and other intellectual property laws. Helwa respects the intellectual property rights of others and we expect our users to honor the same. All title, ownership, and intellectual property rights in the Web Site and the Web Site Content shall remain in Helwa, its affiliates or licensor’s of the Web Site content, as the case may be. We\'re committed to protect the intellectual property rights of third parties and to provide our members with a safe platform to buy or order.',
        term_text23: 'All rights, not generally asserted under this Agreement by Helwa, are hereby reserved. Any data or advertisements contained on, disseminated through, or connected, downloaded or got from any of the services contained on the Web Site or any offer showed on or regarding any service offered on the Web Site ("Website Information") is proposed, exclusively to give general information to the personal use of the User(s), who completely acknowledge any responsibility and liabilities emerging from and out of the use of such Information.',
        term_text24: 'Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the Content and the Marks.',
        term_text25: 'OWNERSHIP',
        term_text26: 'All content provided or displayed by Helwa through the Platform, including (without limitation) all designs, infrastructure, graphics, pictures, illustrations, software, artwork, video, music, sound, names, words, titles, phrases, logos and marks (the “Platform Content”), are owned or licensed by Helwa and are protected by copyright, trade-mark and other intellectual property laws.',
        term_text27: 'Helwa expressly reserves all rights in the Platform, the Platform Services, and the Platform Content that are not specifically granted to you. You acknowledge that all right, title and interest in the Platform, the Platform Services, the Platform Content, and any update, adaptation, translation, customization or derivative work thereof, and all intellectual property rights therein will remain with Helwa (or third party suppliers, if applicable), and that the Platform, Platform Services, and Platform Content are licensed and not “sold” to you.',
        term_text28: 'THIRD PARTY WEBSITES',
        term_text29: 'Helwa may contract with third-party service providers for certain services, such as payment services, and may also provide links to other third-party websites that are not under the control of Helwa.  In addition to these Terms of Use, you may be bound by any additional terms required by the third-party service providers. Helwa makes no representations about any other website to which you may have access through the Platform, and accepts no liability for activities or content on third-party sites. Helwa is not responsible for the accuracy or reliability of any information, data, opinions, advice, or statements made on third-party sites or for the quality of any products or services available on such sites.',
        term_text30: 'TERMINATION',
        term_text31: 'You may terminate the Terms at any time by closing your account, discontinuing your use of this Site and providing Us with a notice of termination. We reserve the right, without notice and in our sole discretion, to terminate your right to use this Site, or any portion of this Site, and to block or prevent your future access to and use of this Site or any portion of this Site.',
        term_text32: 'DISCLAIMERS',
        term_text33: 'Except as expressly provided, this Site, including all Site Content, and services provided on or in connection with this Site is provided on an "AS IS" and "WITH ALL FAULTS" basis without representations, warranties or conditions of any kind, either express or implied. WE DISCLAIM ALL OTHER REPRESENTATIONS, WARRANTIES, CONDITIONS, AND DUTIES, EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES, DUTIES OR CONDITIONS: (A) OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR USE, RESULTS, TITLE, AND NON-INFRINGEMENT; AND (B) CREATED BY TRADE USAGE, COURSE OF DEALING OR COURSE OF PERFORMANCE. We do not represent or warrant that this Site is accurate, complete, reliable, current or error-free. We do not represent or warrant that this Site or our servers are free of viruses or other harmful components.',
        term_text34: 'COPYRIGHT NOTICE',
        term_text35: 'Helwa logos are trademarks of The Videogram Company, All rights reserved. All other trademarks appearing on Helwa are the property of their respective owners.',
        term_text36: 'TRADEMARKS',
        term_text37: 'The names of actual companies and products mentioned herein may be the trademarks of their respective owners. The example companies, organizations, products, domain names, email addresses, logos, people and events depicted herein are fictitious. No association with any real company, organization, product, domain name, email address, logo, person, or event is intended or should be inferred.',
        term_text38: 'Any rights not expressly granted herein are reserved.',
        term_text39: 'CONTACT US',
        term_text40: 'If you have any questions, comments, or complaints about our terms and conditions, please contact us at: admin@helwa.io',
        name_text: "الاسم",
        email_text: "البريد الإلكتروني",
        how_use_helwa: "كيف تستخدم حلوة؟",
        how_use_extension: "إضافة جوجل كروم",
        how_use_ios: "تطبيق Apple iOS",
        how_use_android: "تطبيق Android",
        issue_title_text: "يرجى وصف المشكلة",
        submit_text: "إرسال",
        wait_text: "يرجى الانتظار...",
        thanks_report_text: "شكراً لإعلامنا بذلك. ملاحظاتك تساعدنا على التحسين.",
        feedback_title: "الملاحظات",
        feedback_desc: "ملاحظاتك تساعدنا على التحسين. يرجى تخصيص بضعة دقائق من وقتك لتزويدنا بملاحظاتك القيمة للمساعدة على تحسين حلوة. ",
        feedback_type: "أود أن",
        feedback_type_coupon: "أطلب كوبوناً لمتجر على الإنترنت",
        feedback_type_work: "أعمل معكم كمتجر على الإنترنت",
        feedback_type_complaint: "أقدم شكوى",
        feedback_type_feedback: "ملاحظات عامة",
        thanks_feedback_text: "شكرا لتقديم ملاحظاتك القيمة. ملاحظاتك تساعدنا على التحسين",
        contact_us: "اتصل بنا",
        contact_desc: "شكراً على زيارتك حلوة. نود أن نسمع منك، يرجى التواصل معنا باستخدام النموذج أدناه.",
        list_store_helwa: "أضيف متجري إلى قائمة حلوة",
        thanks_success_text: "شكراً على تواصلك معنا، سنرد عليك قريبا!",
        your_message_text: "رسالتك",
        share_title: "شارك حلوة مع الأصدقاء",
        share_text1: "كلما ازداد عدد مستخدمي حلوة، ازدادت الخصومات التي يمكننا توفيرها لكم من المتاجر على الانترنت.ساعدونا عبر مشاركة حلوة مع الأصدقاء والعائلة",
        share_text2: "يرجى النقر على أي من الخيارات أدناه لمشاركة حلوة.",
        coupon_copied_msg: "تم نسخ الكوبون!",
        copy_code_title: "رموز الكوبونات",
        back_to_store_text: "العودة إلى قائمة المتاجر",
        coupon_code_txt: "رموز الكوبونات",
        refer_title: "قم بإحالتنا",
        no_coupon_text: "لم يتم العثور على كوبون",
        featured_text: "متميز",
        go_to_store_txt: "اذهب إلى الموقع",
        email_login: "سجل ببريدك الالكتروني",
        refer_friends_get_rewarded: "قم بإحالة الأصدقاء ، واحصل على مكافأة",
        share_your_referral_link: "مشاركة رابط الإحالة الخاص بك",
        share_via_email: "سهم عبر البريد الإلكتروني",
        enter_email_address: "أدخل عنوان البريد الالكتروني",
        send: "إرسال",
        outlook: "الآفاق",
        gmail: "Gmail",
        helwa_does_not_store_your_contacts_or_contact_them_without_your_permission: "حلوة لا تخزن جهات الاتصال الخاصة بك أو تتصل بهم دون إذنك.",
        how_it_works: "كيف تعمل",
        invite: "ادعو",
        invite_refer_text: "قم بإحالة الأصدقاء باستخدام الرابط الفريد الخاص بك وقم بتمرير نقاط حلوة.",
        collect_rewards: "جمع المكافآت",
        collect_reward_text: "ستحصل على 10 دراهم إماراتية من نقاط الحلوة عند الشراء لأول مرة.",
        get_gift_cards: "احصل على بطاقات هدايا",
        get_gift_card_text: "استبدل بطاقة الهدايا بمجرد أن تجمع 100 درهم من نقاط حلوة",
        referral_activity: "نشاط الإحالة",
        referral_activity_text: "تعرف على من أشرت إليه وكم عدد نقاط حلوة التي ربحتها.",
        your_referral_progress: "تقدم إحالتك",
        completed: "مكتمل",
        REFERRALS: "الإحالات",
        potential: "القدره",
        five_helwa_points: "+500 نقطة حلوة",
        received: "الواردة",
        zero_helwa_points: "+0 نقاط حلوة",
        helwa_redemption_text: "نقاط حلوة هي طريقتنا في رد الجميل لأعضائنا. اربح نقاط حلوة على جميع أنواع المشتريات - يمكنك استبدال نقاط حلوة ببطاقات الهدايا.",
        what_is_helwa_points: "ما هي نقاط حلوة؟",
        invitation_sent: "دعوة التي وجهت",
        potential_aed10_helwa_points: "10 دراهم إماراتية محتملة من نقاط حلوة",
        please_register_with_helwa_for_get_rewards: "الرجاء التسجيل مع حلوة للحصول على المكافآت",
        report_coupon: "الإبلاغ عن قسيمة",
        yes: "نعم",
        no: "لا",
        ok: "موافق",
        are_you_sure_you_want_to_report_this_coupon_not_working: "هل أنت متأكد أنك تريد الإبلاغ عن هذه القسيمة لا تعمل.",
        coupon_code: "رمز الكوبون",
        please_wait: "انتظر من فضلك...",
        copy_code_and_visit_site: "نسخ الرمز وزيارة الموقع",
        on_minimum_purchase_of_aed: "عند الشراء بحد أدنى درهم إماراتي",
        coupon_terms: "شروط القسيمة",
        how_do_coupon_works: "كيف تعمل القسيمة؟",
        coupon_works_desc: "ما عليك سوى نسخ هذه القسيمة ولصقها في قسم القسيمة / القسيمة أثناء الخروج للحصول على الخصم",
        report_cpupon_title: "الإبلاغ عن هذه القسيمة لا تعمل",
        expires_on: "تنتهي صلاحيته في",
        official_site: "موقع رسمي",
        congratulation_redeemed_text: "مبروك!لقد تم استبدال نقاط حلوة الخاصة بك!",
        congratulation_redeemed_text2: "سيتم إرسال بطاقة الهدية إليك عبر البريد الإلكتروني في غضون 24 ساعة.",
        less_points_err1: "عذرًا ، ليس لديك نقاط كافية لاسترداد هذا العنصر",
        less_points_err2: "يرجى جمع المزيد من النقاط لاسترداد هذه المكافأة.",
        save_money_earn_free_gift_cards_repeat: "وفر المال. اربح بطاقات هدايا مجانية. كرر.",
        helwa_points_is_our_rewards_program_where_you_can_earn_gift_cards: "نقاط حلوة هي برنامج المكافآت الخاص بنا حيث يمكنك كسب بطاقات الهدايا.",
        how_helwa_points_works: "كيف تعمل نقاط حلوة",
        free_and_secure: "إنه مجاني وآمن تمامًا",
        activate_helwa_points: " تفعيل نقاط حلوة",
        earn_helwa_points_when_you_shop: "اربح نقاط حلوة عند التسوق",
        redeem_for_gift_cards: "استرداد مقابل بطاقات الهدايا",
        get_gift_cards_from_popular_stores: "الحصول على بطاقات الهدايا من المتاجر الشهيرة",
        earn_helwa_points_and_get_a_gift_card: "اربح نقاط حلوة واحصل على بطاقة هدايا",
        earn_helwa_crausal_desc: "بمجرد حصولك على نقاط حلوة ، يمكنك استبدالها ببطاقات هدايا من متاجرك المفضلة.",
        browse_gift_vouchers: "تصفح قسائم الهدايا",
        have_more_questions: "هل لديك المزيد من الأسئلة؟",
        learn_more: "اعرف اكثر",
        OVERVIEW: "نظرة عامة",
        REDEEM: "يسترد",
        referral_bonus: "مكافأة الإحالة",
        transaction_history: "سجل التحويلات",
        DATE: "التاريخ",
        TRANSACTION: "التحويلات",
        HELWA_POINTS: "نقاط حلوه",
        no_data_found: "لاتوجد بيانات",
        redeem: "يسترد",
        referral_history: "سجل الإحالة",
        pending_brac: "(قيد الانتظار)",
        points: "نقاط",
        official_site: "موقع رسمي",
        coupons_a_deals: "القسائم والصفقات",
        get_8_cashback_with_helwa: "استرد حتى 8٪ كاش باك مع حلوة",
        earn_helwa_text: "اربح نقاط حلوة عند الشراء لاسترداد بطاقات الهدايا إلى متاجرك المفضلة",
        upto_8_cashback: "حتى 8٪ استرداد نقدي",
        coupons: "الصفقات",
        on_mininum_spend_of: "على الحد الأدنى للإنفاق",
        get_coupon: "الحصول على قسيمة",
        activate_deal: "تنشيط الصفقة",
        expires_on: "تنتهي صلاحيته في",
        activate_offer: "تنشيط العرض",
        close: "اغلق",
        deals: "صفقات",
        what_next_text: "ماذا أفعل بعد ذلك؟",
        installed_para1: "تسوق عبر الإنترنت في متاجرك المفضلة عبر الإنترنت وستظهر لك Helwa في أي وقت تجد فيه رموز القسيمة أو نقاط Helwa Rewards من أجلك.",
        permission_helper_text: "بينما نضيف المزيد من مواقع التسوق إلى قائمتنا ، ستتلقى مطالبة للسماح بأذونات إضافية. تتطلب Helwa هذه الأذونات لقراءة حقل رمز القسيمة على الموقع وإدخال القسائم نيابة عنك.",
        start_shopping: "ابدأ التسوق",
        helwa_cb_term_heading: "نقاط حلوة وشروط الاسترداد النقدي",
        cb_term_text1: "1. يتم منح استرداد النقود من المتاجر عبر الإنترنت كنقاط حلوة ",
        cb_term_text2: "2. بمجرد إجراء المعاملة ، تتم إضافة نقاط حلوة إلى حسابك على أنها معلقة.",
        cb_term_text3: "3. يجب أن تتم الموافقة على استرداد النقود من قبل المتاجر حتى تتم الموافقة على نقاطك. تختلف أوقات الموافقة من متجر لآخر وهي خارجة عن سيطرتنا. قد تستغرق الموافقة على نقاطك ما يصل إلى 3 أشهر ، اعتمادًا على محل.",
        cb_term_text4: "4. قد يرفض المتجر الإلكتروني نقاطك في حالة إرجاع أو معاملة احتيالية أو إحالة غير صحيحة.",
        cb_term_text5: "5. النسبة المئوية لاسترداد النقود المشار إليها هي الحد الأقصى المتاح لهذا المتجر المحدد. قد يختلف هذا المبلغ اعتمادًا على فئة العنصر الذي تم شراؤه.",
        cb_term_text6: "6. إذا لم تحصل على نقاط استرداد نقدي من متجر يقدم استرداد نقدي ، فهذا يعني أن العنصر الذي اشتريته معفى من نقاط الاسترداد النقدي.",
        Logout: "تسجيل الخروج",
        phone: "هاتف",
        privacy_text_g_login: "FACEBOOK AND GOOGLE LOGIN DATA USAGE",
        privacy_textlogin_g_desc: "The Platform uses Facebook and Google Login to offer a convenient method for users to log in. The following data is captured when you log in through Facebook or Google - Name, Email, Photo, Location. This data is used to offer you a more personalized service based on your location. Your email address is used to keep track of your Helwa Points.\nYou may permanently delete your account at any time by logging into the Helwa mobile app (available on Google Play and Apple App Store) and choosing the delete account option in the profile section in the settings tab. By deleting your account, you will also delete all your data that has been collected.<br/>For more information on how to delete your account and your data permanently, you can also contact admin@helwa.io."
    }
});
let lang = (localStorage.getItem('lang')) ? localStorage.getItem('lang') : 'en';
strings.setLanguage(lang);
export default strings;