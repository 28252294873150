import React from 'react';
import Header from '../component/header';
import Footer from '../component/footer';
import strings from '../lang/index';
import Tilt from 'react-tilt';
import API from "../api.js";
import SimpleReactValidator from 'simple-react-validator';
class Privacy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subject:'',
            message:'',
            name:'',
            email:'',
            platform:'Chrome Extension',
            error:[],
            submit:false,
            lockSubmit:false
        }
        this.validator = new SimpleReactValidator({locale: localStorage.getItem('lang')?localStorage.getItem('lang'):'en'});
    }
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({[name]:value});
    }

    submitForm = () =>{
        if (this.validator.allValid() && !this.state.lockSubmit) {
            this.setState({lockSubmit:true});
            let json = {
                "user_id": localStorage.getItem('user_id'),
                "title": this.state.name+' Feedback',
                "text": this.state.message,
                "name": this.state.name,
                "email": this.state.email,
                "platform": this.state.platform,
                "type": 0
            }
            API({
              method: 'POST',
              url: "add_feedback",
              data:json,
            }).then(data => {
              this.setState({submit:true,lockSubmit:false});
            }).catch(() => {
                this.setState({lockSubmit:false});
            });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }
    render(){
    return (
        <React.Fragment>
        <Header />
        <section class="banner">
            <div class="bg-contact100">
            <div class="container-contact100">
                {!this.state.submit && <div class="wrap-contact100">
                        <h1>
                            {strings.report_issue_title}
                        </h1>
                        <p>
                          {strings.reort_issue_desc}  
                        </p><br /><br />
                        <form>
                              <div class="form-group row">
                                <label for="name" class="col-4 col-form-label">{strings.name_text}</label> 
                                <div class="col-8">
                                  <input id="name" name="name" value={this.state.name} onChange={this.handleChange} type="text" class="form-control" required="required" />
                                  {this.validator.message('Name', this.state.name, 'required')}
                                </div>
                              </div>
                              <div class="form-group row">
                                <label for="email" class="col-4 col-form-label">{strings.email_text}</label> 
                                <div class="col-8">
                                  <input id="email" onChange={this.handleChange} name="email" value={this.state.email} type="text" class="form-control" required="required" />
                                  {this.validator.message('Email', this.state.email, 'required')}
                                </div>
                              </div>
                              <div class="form-group row">
                                <label for="source" class="col-4 col-form-label">{strings.how_use_helwa}</label> 
                                <div class="col-8">
                                  <select id="source" onChange={this.handleChange} name="platform" class="custom-select" required="required">
                                    <option value="Chrome Extension">{strings.how_use_extension}</option>
                                    <option value="Apple iOS app">{strings.how_use_ios}</option>
                                    <option value="Android app">{strings.how_use_android}</option>
                                  </select>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label for="issue" class="col-4 col-form-label">{strings.issue_title_text}</label> 
                                <div class="col-8">
                                  <textarea id="issue" onChange={this.handleChange} name="message" value={this.state.message} cols="40" rows="5" class="form-control"></textarea>
                                  {this.validator.message('Feedback', this.state.message, 'required')}
                                </div>
                              </div> 
                              <div class="form-group row">
                                <div class="offset-4 col-8">
                                  <a  class="btn btn-primary" onClick={this.submitForm} >{(this.state.lockSubmit)?strings.wait_text:strings.submit_text}</a>
                                </div>
                              </div>
                            </form>
                </div>}
                {this.state.submit && <div class="wrap-contact100 text-center textsection">
                <h3>{strings.thanks_report_text}</h3>
                <img src="/images/submit.jpg" alt="IMG" style={{margin:'auto'}} />
                </div>}
                
            </div>
            </div>
        </section>
        <Footer />
    </React.Fragment>);
}
}

export default Privacy;